<template>
	<div class="modal fade" id="customProductModal" tabindex="-1" role="dialog" aria-labelledby="customProductModalLabel" aria-hidden="true">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<div style="display:flex;justify-content: space-between">
						<h5 class="modal-title">Custom Product Information:</h5>
						<button type="button" class="close" :disabled="loading" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				</div>
				<div class="modal-body">
					<form method="POST" @submit.prevent="addProduct()">
						<div class="row">
							<div class="col-md-12">
								<label for="name">Product Name</label>
								<input type="text" class="form-control" v-model="customProduct.name" name="name" id="name" required>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="text-right">
									<button type="submit" :disabled="loading" class="btn btn-primary">Add Product</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import WindowValuesMixin from "../Mixins/WindowValuesMixin";
export default {
	mixins: [WindowValuesMixin],

	props: {
		window_values: {
			type: Object,
			required: true
		},
        selectedWindow: {
            type: Number,
            required: false
        },
		type: {
			type: String,
			required: true
		}
	},

	name: 'AddInventoryModal',
	data() {
		return {
			customProduct: {
				name: '',
			},
			loading: false
		}
	},
	methods: {
		addProduct: function() {
			this.loading = true
			let data
			if (this.type === 'window-fixed') {
				data = this.getWindowFixedValues()
			} else if (this.type === 'window-open') {
				data = this.getWindowOpenValues()
			} else if (this.type === 'balcony-door') {
				data = this.getBalconyDoorValues()
			} else if (this.type === 'outer-door') {
				data = this.getOuterDoorValues()
			} else if (this.type === 'window-multiple') {
                data = this.getWindowMultipleValues()
            }

			data.name = this.customProduct.name
			data.type = this.type

			axios.post('/admin/custom-setup', data)
				.then(response => {
					this.loading = false
					this.customProduct.name = ''
					$('#customProductModal').modal('hide')
					this.$snotify.confirm(response.data.message, 'Its Done!', {
						timeout: 5000,
						showProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						buttons: [
							{
								text: 'Close',
								action: (toast) => {
									this.$snotify.remove(toast.id)
								},
								bold: false,
							},
							{
								text: 'View Products?',
								action: () =>
									(window.location = response.data.route),
								bold: true,
							},
						],
					})
				})
				.catch(error => {
					this.loading = false
					console.log(error)
				})
		},

		showModal: function () {
			$('#customProductModal').modal('show');
		},
	}
}
</script>
