<template>
	<div ref="container" class="container-fluid" id="vue">

		<div v-if="!isAdmin" class="row d-lg-none">
			<nav class="navbar fixed-top navbar-light bg-light">
				<div class="container-fluid">
					<p class="">
						Pris {{ totalPrice }} NOK
					</p>
				</div>
			</nav>
		</div>
		<div class="container mt-4">
				<p class="alert alert-info px-4 mb-0">
					På jakt etter noe spesielt eller trenger bare noen råd eller litt veiledning? Vi er klar for å hjelpe: Ring oss direkte på 40 40 35 75, eller kontakt oss på mail: post@kystvinduet.no</p>
		</div>
		<div class="main-window">
			<div class="canvas-wrapper" ref="canvasWrapper">
				<canvas id="window"></canvas>
				<div class='glass-content'>
					<div v-if="changeFTypeGlass.split('.')[1] !== 'none'">
						<img alt='first' v-bind:src="'/img/' + changeFTypeGlass.split('.')[1] + '.png'"/>
					</div>
					<div v-if="changeSTypeGlass.split('.')[1] !== 'standard'">
						<img alt='second' v-bind:src="'/img/' + changeSTypeGlass.split('.')[1] + '.png'"/>
					</div>
				</div>
				<div class="slider">
					<div class="slider-item front-side" v-on:click="changeWindowSide('front_side')"
					     :class="{ disabled : windowSide === 'front_side' }">
						Sett fra innsiden
					</div>
					<div class="slider-item back-side" v-on:click="changeWindowSide('back_side')"
					     :class="{ disabled : windowSide === 'back_side' }">
						Sett fra utsiden
					</div>
					<div class="controls-block">
						<div
							v-if="!animationButtonsHidden"
							v-bind:class="!is_slider_disabled ? ['slider-item', 'open-window'] : ['slider-item', 'open-window', 'disabled']"
							v-on:click="handleAnimation('horizontal')">
							{{ windowOpenedH }}
						</div>
						<div
							v-if="!animationButtonsHidden"
							v-bind:class="!is_slider_disabled ? ['slider-item', 'open-window'] : ['slider-item', 'open-window', 'disabled']"
							v-on:click="handleAnimation('vertical')">
							{{ windowOpenedV }}
						</div>
					</div>
				</div>
			</div>
			<div class="forms-wrapper" ref="formsWrapper">
				<h1 class="title">
					Dobbelt Vindu
				</h1>
				<p class="price d-none d-lg-block">
					Pris {{ totalPrice }} NOK
				</p>
				<div class="select-values">
					<p class="alert  form-text" :class="isAdmin ? 'alert-info' : 'alert-primary'">
						{{ changeInventoryId }}
					</p>
					<div class="custom-select-wrapper input">
						<div class="wrapper">
							<p class="text">Window Type</p>
							<select v-bind:disabled="is_select_disabled" class="custom-select" v-model="windowType" id="windowType">
								<option value="0">Left open, right fixed</option>
								<option value="1">Left fixed, right open</option>
								<option value="2">Both open</option>
								<option value="3">Both fixed</option>
							</select>
							<a href="#" class="get-answer-btn d-none">?</a>
						</div>
					</div>
                    <p class="alert alert-warning form-text">MERK: lukkevinduet har noe tykkere ramme enn det som visualiseringen viser.</p>
					<div class="custom-select-wrapper input">
						<div class="wrapper">
							<p class="text">Antall</p>
							<quantity-input :value="window_values[selectedWindow].quantity" v-model="changeQuantity"></quantity-input>
							<a href="#" class="get-answer-btn d-none">?</a>
						</div>
					</div>
					<div class="custom-select-wrapper input">
						<div class="wrapper">
							<p class="text">Bredde, cm</p>
							<select class="custom-select" v-model="changeWidth" id="changeWidth" v-bind:disabled="is_select_disabled">
								<option value='50'>50 (49 cm standard) (x2)</option>
								<option value='60'>60 (59 cm standard) (x2)</option>
								<option value='70'>70 (69 cm standard) (x2)</option>
								<option value='80'>80 (79 cm standard) (x2)</option>
								<option value='90'>90 (89 cm standard) (x2)</option>
								<option value='100'>100 (99 cm standard) (x2)</option>
								<option value='110'>110 (109 cm standard) (x2)</option>
								<option value='120'>120 (119 cm standard) (x2)</option>
								<option value='130'>130 (129 cm standard) (x2)</option>
								<option value='140'>140 (139 cm standard) (x2)</option>
								<option value='150'>150 (149 cm standard) (x2)</option>
<!--								<option value='160'>160 (159 cm standard)</option>-->
<!--								<option value='170'>170 (169 cm standard)</option>-->
							</select>
							<a href="#" class="get-answer-btn d-none">?</a>
						</div>
						<p class="text-alert" v-if="changeWidth < getMinWidth(sizePrices)">Min
							{{ getMinWidth(sizePrices) }} cm
						</p>
						<p class="text-alert" v-if="changeWidth > getMaxWidth(sizePrices)">Max
							{{ getMaxWidth(sizePrices) }} cm
						</p>
					</div>
					<div class="custom-select-wrapper input">
						<div class="wrapper">
							<p class="text">Høyde, cm</p>
							<select class="custom-select" v-model="changeHeight" id="changeHeight" v-bind:disabled="is_select_disabled">
								<option value='50'>50 (49 cm standard)</option>
								<option value='60'>60 (59 cm standard)</option>
								<option value='70'>70 (69 cm standard)</option>
								<option value='80'>80 (79 cm standard)</option>
								<option value='90'>90 (89 cm standard)</option>
								<option value='100'>100 (99 cm standard)</option>
								<option value='110'>110 (109 cm standard)</option>
								<option value='120'>120 (119 cm standard)</option>
								<option value='130'>130 (129 cm standard)</option>
								<option value='140'>140 (139 cm standard)</option>
								<option value='150'>150 (149 cm standard)</option>
							</select>
							<a href="#" class="get-answer-btn d-none">?</a>
						</div>
						<p class="text-alert" v-if="changeHeight < getMinHeight(sizePrices)">Min
							{{ getMinHeight(sizePrices) }} cm
						</p>
						<p class="text-alert" v-if="changeHeight > getMaxHeight(sizePrices)">Max
							{{ getMaxHeight(sizePrices) }} cm
						</p>
					</div>
					<div class="custom-select-wrapper">
						<p class="text">Farge utside</p>

						<select class="custom-select" v-model="changeOutsideFrameColor" id="changeOutsideFrameColor">
							<option v-for="feature in getFeatureList('frameoutside')" :value='feature.code'>
								{{ feature.name }}
							</option>
						</select>
						<a href="#" class="get-answer-btn d-none">?</a>
					</div>
					<div class="custom-select-wrapper">
							<p class="text">Farge innside</p>
							<select class="custom-select" v-model="changeInsideFrameColor" id="changeInsideFrameColor">
									<option v-for="feature in getFeatureList('frameinside')" :value='feature.code'>
											{{ feature.name }}
									</option>
							</select>
							<a href="#" class="get-answer-btn d-none">?</a>
					</div>
					<div class="custom-select-wrapper">
						<p class="text">Isolasjonsgrad</p>
						<select class="custom-select" id="isolation" v-model="changeIsolation">
							<option v-for="feature in getFeatureList('isolation')" :value='feature.code'>
								{{ feature.name }}
							</option>
						</select>
						<a href="#" class="get-answer-btn d-none">?</a>
					</div>
					<div class="custom-select-wrapper">
						<p class="text">Type glass</p>
						<div style="flex-grow: 1; display: flex; flex-direction: column;">
							<select class="custom-select" id="firstglass" style="width: 100%;"
							        v-model="changeFTypeGlass">
								<option v-for="feature in getFeatureList('firstglass')" :value='feature.code'>
									{{ feature.name }}
								</option>
							</select>
							<select class="custom-select" id="secondglass" style="width: 100%;"
							        v-model="changeSTypeGlass">
								<option v-for="feature in getFeatureList('secondglass')" :value='feature.code'>
									{{ feature.name }}
								</option>
							</select>
						</div>
						<a href="#" class="get-answer-btn d-none">?</a>
					</div>
					<div class="custom-select-wrapper">
						<p class="text">Notspor</p>
						<select class="custom-select" v-model="changeNotspor">
							<option v-for="feature in getFeatureList('notspor')" :value='feature.code'>
								{{ feature.name }}
							</option>
						</select>
						<a href="#" class="get-answer-btn d-none">?</a>
					</div>
					<div class="custom-select-wrapper">
						<p class="text">Barnesikring</p>
						<select class="custom-select" v-model="changeChildsafe">
							<option v-for="feature in getFeatureList('childsafe')" :value='feature.code'>
								{{ feature.name }}
							</option>
						</select>
						<a href="#" class="get-answer-btn d-none">?</a>
					</div>
					<div class="custom-select-wrapper">
						<p class="text">Sprosser horisontalt</p>
						<select class="custom-select" id="changeHorizontalGrid" v-model="changeHorizontalGrid">
							<option v-for="feature in getFeatureList('horizontalgrid')" :value='feature.code'>
								{{ feature.name }}
							</option>
						</select>
						<a href="#" class="get-answer-btn d-none">?</a>
					</div>
					<div class="custom-select-wrapper">
						<p class="text">Sprosser vertikalt</p>
						<select class="custom-select" v-model="changeVerticalGrid">
							<option v-for="feature in getFeatureList('verticalgrid')" :value='feature.code'>
								{{ feature.name }}
							</option>
						</select>
						<a href="#" class="get-answer-btn d-none">?</a>
					</div>
					<div v-if="isHandleSelectionVisible" class="custom-select-wrapper">
						<p class="text">Håntak side</p>
						<select class="custom-select browser-default" v-model="changeHandleSide" id="changeHandleSide"
						        v-bind:disabled="is_select_disabled || isHandleSelectionDisabled || isHandleSideDisabled">
							<option v-for="feature in getFeatureList('handleside')" :value='feature.code'>
								{{ feature.name }}
							</option>
						</select>
						<a href="#" class="get-answer-btn d-none">?</a>
					</div>
					<div v-if="isHandleSelectionVisible" class="custom-select-wrapper">
						<p class="text">Håndtak farge</p>
						<select class="custom-select" v-model="changeHandleColor"  v-bind:disabled="is_select_disabled || isHandleSelectionDisabled">
							<option v-for="feature in getFeatureList('handlecolor')" :value='feature.code'>
								{{ feature.name }}
							</option>
						</select>
						<a href="#" class="get-answer-btn d-none">?</a>
					</div>
					<template v-if="isAdmin">
						<div class="button-wrapper">
							<a href="#" @click.prevent="showInventoryModal()" class="add-to-cart">Lag nytt produkt for lagerbeholdning</a>
						</div>
						<div class="button-wrapper">
							<a href="#" class="add-to-cart" :class="{ 'disabled' : !cartId }"  @click.prevent="addToCart">Legg til rabatt, kosttillegg, frakt og lag tilbud</a>
						</div>
						<div class="button-wrapper">
							<a href="#" @click.prevent="showCustomProductModal()" class="add-to-cart" > Lag ny produktlenke med dette oppsettet</a>
						</div>
						<div class="button-wrapper">
							<a href="/admin/cart" :class="{ 'disabled' : !cartId }" class="add-to-cart">Gå til handlekurv</a>
						</div>
					</template>
					<template v-else>
						<div class="button-wrapper">
							<a href="#" class="add-to-cart" @click.prevent="addToCart">Legg i handlekurv</a>
						</div>
						<div v-if="cartId" class="button-wrapper"><a href="cart" class="add-to-cart">Gå til handlekurv</a>
						</div>
					</template>
				</div>
			</div>
		</div>
		<add-inventory-modal v-if="isAdmin" :window_values="window_values[selected_window]" type="window-multiple" ref="inventoryModal"></add-inventory-modal>
		<create-custom-product-modal v-if="isAdmin" :window_values="window_values" :selectedWindow="selectedWindow" type="window-multiple" ref="customProductModal"></create-custom-product-modal>
	</div>
</template>

<script>
import GeneralMixin from "../Mixins/GeneralMixin";
import * as THREE from "three";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import WindowValuesMixin from "../Mixins/WindowValuesMixin";
import TWEEN from '@tweenjs/tween.js'


const colors = [
	{
		text: 'hvit',
		hex: '0xa6a6a6',
	},
	{
		text: 'Grå',
		hex: '0x666666'
	},
	{
		text: 'Mørk brun',
		hex: '0x33230b'
	},
	{
		text: 'Svart',
		hex: '0x1c1c1a'
	},
]

const grid_colors = [
	{
		text: 'hvit',
		hex: '0xa6a6a6',
	},
	{
		text: 'Grå',
		hex: '0x666666'
	},
	{
		text: 'Mørk brun',
		hex: '0x33230b'
	},
	{
		text: 'Svart',
		hex: '0x1c1c1a'
	},
]

const color_price = {
	outside: {
		white: 0,
		gray: 0,
		brown: 0,
		black: 0,
	},
	inside: {
		white: 0,
		gray: 0,
		brown: 0,
		black: 0,
	},
	bothsides: {
		white: 0,
		gray: 0,
		brown: 0,
		black: 0,
	},
}

const handle_colors = [
	{
		text: 'hvit',
		hex: '0xffffff',
		price: 0,
	},
	{
		text: 'Grå',
		hex: '0xc9c9c9',
		price: 0,
	},
	{
		text: 'sølv',
		hex: '0xc9c9c9',
		price: 0,
	},
	{
		text: 'Svart',
		hex: '0x3d3d3d',
		price: 0,
	},
]

const size_values = {
	width: {
		min: 39.5,
		max: 170,
	},
	height: {
		min: 41.5,
		max: 150,
	},
}

const type_of_glass  = {
	first_type: [
		{
			text: 'Ingen sikkerhetsglass',
			price: 0,
		},
		{
			text: 'Sikkerhetsglass innside',
			price: 0,
		},
		{
			text: 'Sikkerhetsglass utside',
			price: 0,
		},
		{
			text: 'Sikkerhetsglass begge sider',
			price: 0,
		},
	],
	second_type: [
		{
			text: 'Standard glass',
			price: 0,
		},
		{
			text: 'Frosta glass',
			price: 0,
		},
		{
			text: 'Soldempende glass',
			price: 0,
		},
		{
			text: 'tonet glass',
			price: 0,
		},
	],
}

const notspor = [
	{
		text: '0mm',
		price: 0
	},
	{
		text: '10mm',
		price: 0,
	},
	{
		text: '13mm',
		price: 0,
	},
]

const childsafe = [
	{
		text: 'Nei',
		price: 0
	},
	{
		text: 'Ja',
		price: 0,
	},
]

const grids = {
	price_add: {
		default: 0,
		not_default: 0,
	},
	horizontal: {
		type: 'horizontal',
		values: [0, 1, 2, 3],
		table: {
			0: [0, 2, 3, 4],
			1: [2, 4, 6, 8],
			2: [3, 6, 9, 12],
			3: [4, 8, 12, 16],
		},
		active_value: null,
	},
	vertical: {
		type: 'vertical',
		values: [0, 1, 2, 3],
		active_value: null,
	}
}

const degree_isolation = {
	values: [
		{
			text: '2-lags'
		},
		{
			text: '3-lags',
			add_c: 0,
		}
	]
}

const getDefaultWindowValues = ({index, defaultWidth = 100, defaultHeight = 100, defaultQty = 1, defaultPrice = 0}) => ({
		index: index,
		inventory_id: 0,
		inventory_stock: 0,
		quantity: defaultQty,
		width: defaultWidth,
		height: defaultHeight,
		active_handle_colors: null,
		notspor: null,
		childsafe: null,
		handle_side: null,
		active_colors: {
			inside: '',
			outside: '',
			grid_inside: '',
			grid_outside: '',
		},
		active_type_of_glass: {
			first: null,
			second: null,
		},
		vertical_grid: null,
		horizontal_grid: null,
		grid_color: null,
		active_isolation: null,
		total_price: defaultPrice,
})

const loader = new GLTFLoader();

export default {
	mixins: [
		GeneralMixin,
		WindowValuesMixin
	],
	props: {
		windowCount: {
			required: false,
			default: 2
		},
	},
	data() {
		return {
			window_type: 0,
			selected_window: 0,
			grids_array: [],
			colors: colors,
			grid_colors: grid_colors,
			color_price: color_price,
			handle_colors: handle_colors,
			size_values: size_values,
			type_of_glass: type_of_glass,
			notspor: notspor,
			childsafe: childsafe,
			grids: grids,
			core_table: {},
			degree_isolation: degree_isolation,
			window_values: [],
			models: [],
			three: {

			},
			exchange_rate: 0,
			rebate: 0,
			margin: 0,
			tax: 0,
			window_opened: [],
			static_models: [
				{
					name: 'frame_inside',
					z: 0.17
				},
				{
					name: 'frame_inside_front',
					z: 0.02
				},
				{
					name: 'frame_outside_colored',
					z: -0.12
				},
				{
					name: 'frame_inside_colored',
					z: 0
				},
				{
					name: 'glass',
					z: 0.16
				}
			],
			is_select_disabled: false,
			is_slider_disabled: false,
		}
	},

	created() {
		if (this.variation && this.variation.hasOwnProperty('length') && this.variation.length > 0) {
			for (let index = 0; index < this.variation; index++) {
				const variation = array[index];

				const {
					width,
					height,
					insideFrameColor,
					outsideFrameColor,
					handleColor,
					notspor,
					childsafe,
					handleSide,
					horizontalGrid,
					verticalGrid,
					fTypeGlass,
					sTypeGlass,
					isolation,
				} = variation

				this.window_values[index].width = width
				this.window_values[index].height = height
				this.window_values[index].active_colors.inside = insideFrameColor ?? ''
				this.window_values[index].active_colors.outside = this.variation['outsideFrameColor'] ?? ''
				this.window_values[index].active_colors.grid_inside = outsideFrameColor ?? ''
				this.window_values[index].active_handle_colors = handleColor ?? ''
				this.window_values[index].notspor = notspor ?? ''
				this.window_values[index].childsafe = childsafe ?? ''
				this.window_values[index].handle_side = handleSide ?? ''
				this.window_values[index].horizontal_grid = horizontalGrid ?? ''
				this.window_values[index].vertical_grid = verticalGrid ?? ''
				this.window_values[index].active_type_of_glass.first = fTypeGlass ?? ''
				this.window_values[index].active_type_of_glass.second = sTypeGlass ?? ''
				this.window_values[index].active_isolation = isolation ?? ''
				this.window_values[index].grid_color = insideFrameColor ?? ''
			}
		} else {
			for (let index = 0; index < this.windowCount; index++) {
				const model = getDefaultWindowValues({index});
				model.active_colors.inside = 'frameinside.white'
				model.active_colors.outside = 'frameoutside.white'
				model.active_colors.grid_inside = 'frameinside.white'
				model.active_handle_colors = 'handlecolor.white'
				model.notspor = 'notspor.ten'
				model.childsafe = 'childsafe.no'
				model.handle_side = index > 0 ? 'handleside.left' : 'handleside.right'
				model.horizontal_grid = 'horizontalgrid.none'
				model.vertical_grid = 'verticalgrid.none'
				model.active_type_of_glass.first = 'firstglass.none'
				model.active_type_of_glass.second = 'secondglass.standard'
				model.active_isolation = 'isolation.two-layers'
				model.grid_color = 'gridcolor.white'

				this.window_values.push(model);
				this.window_opened.push({h: false, v: false })
			}
		}

		this.three.parent = new THREE.Group()
	},
	mounted() {
		this.init();
		this.three.scene.add(this.three.parent)
	},
	computed: {
		totalPrice: function () {
			const {total_price} = this.getValues(this.selectedWindow)

			if (this.isValues()) {
				return (this.changeQuantity * total_price).toFixed(2)
			}

			return (this.changeQuantity * Math.round(total_price * this.exchange_rate)).toFixed(2)
		},
		windowOpenedH: function () {
			return this.window_opened[this.selectedWindow].h ? 'Lukk vindu' : 'Åpne helt'
		},
		windowOpenedV: function () {
			return this.window_opened[this.selectedWindow].v ? 'Lukk vindu' : 'Åpne i topp'
		},
		changeInventoryId: function () {
			//TODO maybe needs improvement once the price and features for this product are implemented

			let _this = this
			if (this.isValues()) {
				const element = this.window_values[this.selectedWindow];
                let data = this.getWindowMultipleValues()
                $.post("/inventory", data, function (data) {
                    _this.window_values[_this.selectedWindow].inventory_id = data['id']
                    _this.window_values[_this.selectedWindow].inventory_stock = data['quantity']
                    _this.window_values[_this.selectedWindow].total_price = data['price']
                });
            }

			return this.window_values[this.selectedWindow].inventory_stock < this.window_values[this.selectedWindow].quantity ? 'LEVERINGSTID 3-4 UKER ' : this.window_values[this.selectedWindow].inventory_stock + ' på lager. Sendes innen 1-3 dager';
		},
		isHandleSelectionVisible: {
			get: function() {
				return this.window_type !== 3;


			}
		},
		isHandleSelectionDisabled: {
			get: function() {
				if (this.window_type === 3) { //fixed
					return true;
				}
				if (this.window_type === 0 && this.selected_window !== 0) {
					return true
				}
				return this.window_type === 1 && this.selected_window !== 1;


			}
		},
		isHandleSideDisabled: {
			get: function() {
				return this.window_type === 2;


			}
		},
		animationButtonsHidden: {
			get: function() {
				return this.window_type === 3;


			}
		},
		windowType: {
			get: function () {
				return this.window_type
			},
			set: function (windowType = 0) {
				const _this = this;

				new Promise(function(resolve, reject){
					_this.window_type = Number(windowType)

					if (_this.window_type === 0) {
						_this.selected_window = 0
					} else if (_this.window_type === 1) {
						_this.selected_window = 1
					}

					for (let index = 0; index < _this.windowCount; index++) {
						_this.window_values[index].handle_side = index > 0 ? 'handleside.left' : 'handleside.right'
					}

					resolve()
				}).then(function(){
					_this.updateModels()
				})
			}
		},
		selectedWindow: {
			get: function () {
				return this.selected_window
			},
			set: function (windowIndex = 0) {
				this.selected_window = Number(windowIndex)
			}
		},
		changeQuantity: {
			get: function () {
				const {quantity} = this.getValues(this.selectedWindow)

				return quantity
			},
			set: function (value) {
				this.window_values[this.selectedWindow].quantity = value
			}
		},
		changeInventoryStatus: {
			get: function () {
				const {inventory_status} = this.getValues(this.selectedWindow)
				return inventory_status
			},
			set: function (value) {
				//this.window_values[this.selectedWindow].inventory_status = value
				this.window_values.forEach((window) => {
					window.inventory_status = value
				})
			}
		},
		changeWidth: {
			get: function () {
				const {width} = this.getValues(this.selectedWindow)
				return width
			},
			set: function (value) {
				const _this = this;

				new Promise(function(resolve, reject){
					for (let index = 0; index < _this.windowCount; index++) {
						_this.window_values[index].width = value
					}
					resolve()
				}).then(function(){
					if (Number(value) >= _this.size_values.width.min && Number(value) <= _this.size_values.width.max) {
						_this.updateModels()
					}
				})
			}
		},
		changeHeight: {
			get: function () {
				const {height} = this.getValues(this.selectedWindow)

				return height
			},
			set: function (value) {

				const _this = this;

				new Promise(function(resolve, reject){
					for (let index = 0; index < _this.windowCount; index++) {
						_this.window_values[index].height = value
					}
					resolve()
				}).then(() => {
					if (Number(value) >= _this.size_values.height.min && Number(value) <= _this.size_values.height.max) {
						_this.updateModels()
					}
				})
			}
		},
		changeVerticalGrid: {
			get: function () {
				const {vertical_grid} = this.getValues(this.selectedWindow)

				return vertical_grid
			},
			set: function (data) {
				const _this = this;

				new Promise(function(resolve, reject){
					for (let index = 0; index < _this.windowCount; index++) {
						_this.window_values[index].vertical_grid = data
					}
					resolve()
				}).then(() => {
						_this.$nextTick(() => {
							for (let index = 0; index < _this.windowCount; index++) {
								_this.changeModelSize(index)
							}
						})
				})
			}
		},
		changeFTypeGlass: {
			get: function () {
				const {active_type_of_glass} = this.getValues(this.selectedWindow)

				return active_type_of_glass.first
			},
			set: function (data) {
				this.window_values.forEach((window) => {
					window.active_type_of_glass.first = data
				})
			}
		},
		changeSTypeGlass: {
			get: function () {
				const {active_type_of_glass} = this.getValues(this.selectedWindow)

				return active_type_of_glass.second
			},
			set: function (data) {
				if (data.split(".")[1] === "sunprotect") {
					this.models.forEach((model) => {
						model.material_glass.color.setHex(Number(0x010101))
						model.material_glass.opacity = 0.1;
					})
				} else if (data.split(".")[1] === "toned") {
					this.models.forEach((model) => {
						model.material_glass.color.setHex(Number(0x090909))
						model.material_glass.opacity = 0.2;
					})

				} else{
					this.models.forEach((model) => {
						model.material_glass.color.setHex(Number(0x7fb3aa))
						model.material_glass.opacity = 0.2;
					})

				}
				this.window_values.forEach((window) => {
					window.active_type_of_glass.second = data
				})
			}
		},
		changeIsolation: {
			get: function () {
				const {active_isolation} = this.getValues(this.selectedWindow)
				return active_isolation
			},
			set: function (data) {
				this.window_values[this.selectedWindow].active_isolation = data
			}
		},
		changeHorizontalGrid: {
			get: function () {
				const {horizontal_grid} = this.getValues(this.selectedWindow)
				return horizontal_grid
			},
			set: function (data) {
				const _this = this;

				new Promise(function(resolve, reject){
					for (let index = 0; index < _this.windowCount; index++) {
						_this.window_values[index].horizontal_grid = data
					}
					resolve()
				}).then(() => {
						_this.$nextTick(() => {
							for (let index = 0; index < _this.windowCount; index++) {
								_this.changeModelSize(index)
							}
						})
				})
			}
		},
		changeHandleSide: {
			get: function () {
				const {handle_side} = this.getValues(this.selectedWindow)
				return handle_side
			},
			set: function (side) {
				this.window_values.forEach((window) => {
					window.handle_side = side
				})

				this.window_values.keys().forEach((index) => {
					this.handleSide(index)
				})
			}
		},
		changeInsideFrameColor: {
			get: function () {
				const {active_colors} = this.getValues(this.selectedWindow)
				return active_colors.inside
			},
			set: function (text) {

				const _this = this;

				for (let index = 0; index < this.windowCount; index++) {
						this.window_values[index].active_colors.inside = text
						this.window_values[index].active_colors.grid_inside = text

						this.models[index][`frame_inside_${index}`].material.color.set(Number(_this.getHex(text)))

						const gridsLength = this.grids_array[index].length

						for (let rn = 0; rn < gridsLength; rn++) {
							let k = _this.grids_array[index][rn].name
							if (k.includes('grid_horizontal_inside') || k.includes('grid_vertical_inside')) {
								_this.models[index][k].material.color.setHex(Number(_this.getHex(text)))
							}
						}
				}

				if(text !== 'frameinside.white'){
						this.changeOutsideFrameColor = text.replace('frameinside', 'frameoutside');
				}
			}
		},
		changeOutsideFrameColor: {
			get: function () {
				const {active_colors} = this.getValues(this.selectedWindow)
				return active_colors.outside
			},
			set: function (text) {

				const _this = this;

				for (let index = 0; index < _this.windowCount; index++) {

						const {active_colors} = _this.getValues(index)

						_this.window_values[index].active_colors.outside = text
						_this.window_values[index].active_colors.grid_outside = text

						_this.models[index][`frame_outside_colored_${index}`].material.color.setHex(Number(_this.getHex(active_colors.outside)))

						for (let rn = 0; rn < _this.grids_array[index].length; rn++) {
							let k = _this.grids_array[index][rn].name
							if (k.includes('grid_horizontal_outside') || k.includes('grid_vertical_outside')) {
								_this.models[index][k].material.color.setHex(Number(_this.getHex(text)))
							}
						}
				}

				if(this.changeInsideFrameColor !== text.replace('frameoutside', 'frameinside')) {
						this.changeInsideFrameColor = text.replace('frameoutside', 'frameinside')
						document.querySelectorAll("#changeInsideFrameColor option").forEach(opt => {
								opt.disabled = (opt.value !== text.replace('frameoutside', 'frameinside')) && (opt.value !== 'frameinside.white');
						})
				}
			}
		},
		changeHandleColor: {
			get: function () {
				const {active_handle_colors} = this.getValues(this.selectedWindow)
				return active_handle_colors
			},
			set: function (text) {

				const _this = this;

				new Promise(function(resolve, reject){
					for (let index = 0; index < _this.windowCount; index++) {
							_this.window_values[index].active_handle_colors = text
					}
					resolve()
				}).then(() => {
						_this.$nextTick(() => {
							for (let index = 0; index < _this.windowCount; index++) {
								for (let c = 0; c < _this.models[index].handle.length; c++) {
									_this.models[index].handle[c].material.color.set(Number(_this.getHex(text)))
								}
							}
						})
				})
			}
		},
		changeNotspor: {
			get: function () {
				const {notspor} = this.getValues(this.selectedWindow)
				return notspor
			},
			set: function (text) {
				this.window_values[this.selectedWindow].notspor = text
			}
		},
		changeChildsafe: {
			get: function () {
				const {childsafe} = this.getValues(this.selectedWindow)
				return childsafe
			},
			set: function (text) {
				this.window_values[this.selectedWindow].childsafe = text
			}
		},
	},
	methods: {
		handleAnimation: function(animation) {
			if (this.window_type === 3) {
				return;
			}

			let openIndex = []

			if (this.window_type === 0) {
				openIndex.push(0)
			} else if(this.window_type === 1) {
				openIndex.push(1)
			} else if(this.window_type === 2) {
				openIndex.push(0, 1)
			}

			if(animation === 'horizontal') {
				openIndex.forEach(i => {
					this.openWindow('horizontal', i)
				})

			} else if (animation === 'vertical') {
				openIndex.forEach(i => {
					this.openWindow('vertical', i)
				})
			}
		},
		updateModels: function() {
			for (let index = 0; index < this.windowCount; index++) {
				this.changeModelSize(index)
			}
		},
		getValues: function(index = 0) {
			return this.window_values.find(w => w.index === index)
		},
		addHandleModel: function(handleGltf, settings) {
			settings.handle = null

			const handle = []

			handleGltf.scene.traverse((child) => {
				if (child.material) {
					handle.push(child)
					child.material.metalness = 0.35
					child.material.roughness = 0
					child.material.color.setHex(0xffffff)
				}
			});

			const handleModel = handleGltf.scene

			settings.handle = handle

			handleModel.scale.x = settings.defScale.x
			handleModel.scale.y = settings.defScale.y
			handleModel.scale.z = settings.defScale.z

			handleModel.position.x = settings.defPosition.x;
			handleModel.position.y = settings.defPosition.y
			handleModel.position.z = settings.defPosition.z

			handleModel.rotation.x = THREE.MathUtils.degToRad(90)
			handleModel.rotation.y = THREE.MathUtils.degToRad(270)

			settings.handle_model = handleModel

			return settings;
		},
		createModel: function () {
			for (let index = 0; index < this.windowCount; index++) {
				const window = this.getValues(index)

				const inside = new THREE.Shape();
				const outside = new THREE.Shape();
				const hole = new THREE.Path();

				const material_outside = new THREE.MeshPhongMaterial({
					color: Number(this.getHex(window.active_colors.outside)),
					specular: 0xffffff,
					shininess: 0,
					depthWrite: true
				});
				const material_outside_colored = new THREE.MeshPhongMaterial({
					color: Number(this.getHex(window.active_colors.outside)),
					specular: 0xffffff,
					shininess: 0,
				});
				const material_inside = new THREE.MeshPhongMaterial({
					color: Number(this.getHex(window.active_colors.inside)),
					specular: 0xffffff,
					shininess: 0,
				});
				const material_inside_colored = new THREE.MeshPhongMaterial({
					color: Number(this.getHex(window.active_colors.inside)),
					specular: 0xffffff,
					shininess: 0,
				});
				const material_grid = new THREE.MeshPhongMaterial({
					color: Number(this.getHex(window.active_colors.inside)),
					shininess: 0,
				});
				const material_grid_colored = new THREE.MeshPhongMaterial({
					color: Number(0xffffff),
					shininess: 0,
				});
				const material_glass = new THREE.MeshPhongMaterial({
					color: Number(0x7fb3aa),
					shininess: 0,
					transparent: true,
					opacity: 0.2,
				});
				const default_settings = {
					depth: 1,
					bevelEnabled: false,
					steps: 1,
					bevelSegments: 2,
					bevelSize: 1,
					bevelThickness: 1
				};

				const inside_settings = {
					depth: 0.6,
					bevelEnabled: true,
					bevelSegments: 2,
					bevelSize: 1,
					bevelThickness: 1
				};

				const outside_settings = {
					depth: 1,
					bevelEnabled: true,
					bevelSegments: 2,
					bevelSize: 1,
					bevelThickness: 1
				};

				const extrudeSettingsCustom = {
					depth: 0.01,
					bevelEnabled: false,
					bevelSegments: 1,
					bevelSize: 1,
					bevelThickness: 1
				};


				const handleDefPos = {x: 0, y: 0, z:0.35}

				if (window.handleSide === 'handleSide.left') {
					handleDefPos.x = 0.2
					handleDefPos.y = window.height <= 50 ? 0.2 : 0
					handleDefPos.z = 0.35
				} else {
					let s = 1.1
                    handleDefPos.x = ((window.width - this.size_values.width.min) * 0.05) + s * 3
				}

				const defSettings = {
					inside,
					outside,
					hole,
					material_outside,
					material_outside_colored,
					material_inside,
					material_inside_colored,
					material_grid,
					material_grid_colored,
					material_glass,
					default_settings,
					inside_settings,
					outside_settings,
					extrudeSettingsCustom,
					defPosition: handleDefPos,
					defScale: {x: 0.013, y: 0.013, z:0.013}
				}

				loader.load('https://butikk.kystvinduet.no/img/hand_model_2.glb', (handleGltf) => {

					const settings = this.addHandleModel(handleGltf, defSettings)

					outside.add(settings.handle)

					this.models.push(settings)

					this.changeModelSize(index)
				})
			}
		},
		getMesh: function (windowIndex, name, size, grids_config) {

			const grid_depth = Math.PI / 3

			if (name === `frame_inside_${windowIndex}`) {
				this.models[windowIndex].inside = new THREE.Shape();
				this.models[windowIndex].hole = new THREE.Path();
				this.f_rect(this.models[windowIndex].inside, windowIndex, 0, size.width, size.height, 1);
				this.f_rect_reverse(this.models[windowIndex].hole, 2, 2, size.width - 4, size.height - 4, 1);
				this.models[windowIndex].inside.holes.push(this.models[windowIndex].hole);
				let geometry_inside = new THREE.ExtrudeBufferGeometry(this.models[windowIndex].inside, this.models[windowIndex].inside_settings);
				let mesh = new THREE.Mesh(geometry_inside, this.models[windowIndex].material_inside);
				mesh.name = name
				return mesh
			} else if (name === `frame_inside_front_${windowIndex}`) {
				this.models[windowIndex].inside = new THREE.Shape();
				this.models[windowIndex].hole = new THREE.Path();
				this.f_rect(this.models[windowIndex].inside, 0, 0, size.width, size.height, 1);
				this.f_rect_reverse(this.models[windowIndex].hole, 2, 2, size.width - 4, size.height - 4, 1);
				this.models[windowIndex].inside.holes.push(this.models[windowIndex].hole);
				let geometry_inside = new THREE.ExtrudeBufferGeometry(this.models[windowIndex].inside, this.models[windowIndex].inside_settings);
				let mesh = new THREE.Mesh(geometry_inside, this.models[windowIndex].material_outside_colored);
				mesh.name = name
				return mesh
			} else if (name === `frame_outside_${windowIndex}`) {
				this.models[windowIndex].outside = new THREE.Shape();
				this.models[windowIndex].hole = new THREE.Path();
				this.f_rect(this.models[windowIndex].outside, -1, -1, size.width + 2, size.height + 2, 1);
				this.f_rect_reverse(this.models[windowIndex].hole, 2, 2, size.width - 4, size.height - 4, 1);
				this.models[windowIndex].outside.holes.push(this.models[windowIndex].hole);
				let geometry_outside = new THREE.ExtrudeBufferGeometry(this.models[windowIndex].outside, this.models[windowIndex].default_settings);
				let mesh = new THREE.Mesh(geometry_outside, this.models[windowIndex].material_outside);
				//mesh.name = name
				return mesh
			} else if (name === `frame_outside_colored_${windowIndex}`) {
				this.models[windowIndex].outside = new THREE.Shape();
				this.models[windowIndex].hole = new THREE.Path();
				this.f_rect(this.models[windowIndex].outside, -1, -1, size.width + 2, size.height + 2, 1);
				this.f_rect_reverse(this.models[windowIndex].hole, 2, 2, size.width - 4, size.height - 4, 1);
				this.models[windowIndex].outside.holes.push(this.models[windowIndex].hole);
				let geometry_outside = new THREE.ExtrudeBufferGeometry(this.models[windowIndex].outside, this.models[windowIndex].outside_settings);
				let mesh = new THREE.Mesh(geometry_outside, this.models[windowIndex].material_outside_colored);
				mesh.name = name
				return mesh
			} else if (name === `frame_inside_colored_${windowIndex}`) {
				this.models[windowIndex].outside = new THREE.Shape();
				this.models[windowIndex].hole = new THREE.Path();
				this.f_rect(this.models[windowIndex].outside, -1, -1, size.width + 2, size.height + 2, 1);
				this.f_rect_reverse(this.models[windowIndex].hole, 2, 2, size.width - 4, size.height - 4, 1);
				this.models[windowIndex].outside.holes.push(this.models[windowIndex].hole);
				let geometry_outside = new THREE.ExtrudeBufferGeometry(this.models[windowIndex].outside, this.models[windowIndex].outside_settings);
				let mesh = new THREE.Mesh(geometry_outside, this.models[windowIndex].material_inside);
				mesh.name = name
				return mesh
			} else if (name === `glass_${windowIndex}`) {
				this.models[windowIndex].outside = new THREE.Shape();
				this.f_rect(this.models[windowIndex].outside, 2, 2, size.width - 4, size.height - 4, 1);
				let geometry_glass = new THREE.ExtrudeBufferGeometry(this.models[windowIndex].outside, this.models[windowIndex].extrudeSettingsCustom);
				let mesh = new THREE.Mesh(geometry_glass, this.models[windowIndex].material_glass);
				mesh.name = name
				return mesh
			} else if (name.indexOf(`grid_vertical_outside`) > -1) {
				this.models[windowIndex].inside = new THREE.Shape();
				this.models[windowIndex].hole = new THREE.Path();
				this.f_rect(this.models[windowIndex].inside, grids_config, 2, 2, size.height - 4, 1);

				const extrudeSettings = {
					steps: 1,
					depth: grid_depth,
					bevelEnabled: false,
					bevelThickness: 1,
					bevelSize: 0,
					bevelOffset: 1,
					bevelSegments: 0
				};

				let geometry_inside = new THREE.ExtrudeBufferGeometry(this.models[windowIndex].inside, extrudeSettings);
				let mesh = new THREE.Mesh(geometry_inside, this.models[windowIndex].material_grid_colored);
				mesh.material.color.setHex(Number(0x0ff000))
				mesh.name = name
				return mesh
			} else if (name.indexOf(`grid_horizontal_outside`) > -1) {
				this.models[windowIndex].inside = new THREE.Shape();
				this.models[windowIndex].hole = new THREE.Path();
				this.f_rect(this.models[windowIndex].inside, 2, grids_config, size.width - 4, 2, 1);

				const extrudeSettings = {
					steps: 1,
					depth: grid_depth,
					bevelEnabled: false,
					bevelThickness: 1,
					bevelSize: 0,
					bevelOffset: 1,
					bevelSegments: 0
				};

				let geometry_inside = new THREE.ExtrudeBufferGeometry(this.models[windowIndex].inside, extrudeSettings);
				let mesh = new THREE.Mesh(geometry_inside, this.models[windowIndex].material_grid_colored);
				mesh.name = name
				return mesh
			} else if (name.indexOf(`grid_vertical_inside`) > -1) {
				this.models[windowIndex].inside = new THREE.Shape();
				this.models[windowIndex].hole = new THREE.Path();
				this.f_rect(this.models[windowIndex].inside, grids_config, 0, 2, size.height, 1);

				const extrudeSettings = {
					steps: 1,
					depth: grid_depth,
					bevelEnabled: false,
					bevelThickness: 1,
					bevelSize: 0,
					bevelOffset: 1,
					bevelSegments: 0
				};

				let geometry_inside = new THREE.ExtrudeBufferGeometry(this.models[windowIndex].inside, extrudeSettings);
				let mesh = new THREE.Mesh(geometry_inside, this.models[windowIndex].material_grid);
				mesh.name = name
				return mesh
			} else if (name.indexOf(`grid_horizontal_inside`) > -1) {
				this.models[windowIndex].inside = new THREE.Shape();
				this.models[windowIndex].hole = new THREE.Path();
				this.f_rect(this.models[windowIndex].inside, 0, grids_config, size.width - 5, 2, 1);

				const extrudeSettings = {
					steps: 1,
					depth: grid_depth,
					bevelEnabled: false,
					bevelThickness: 1,
					bevelSize: 0,
					bevelOffset: 1,
					bevelSegments: 0
				};

				let geometry_inside = new THREE.ExtrudeBufferGeometry(this.models[windowIndex].inside, extrudeSettings);
				let mesh = new THREE.Mesh(geometry_inside, this.models[windowIndex].material_grid);
				mesh.name = name
				return mesh
			}
		},
		changeWindowSide: function(key) {
			this.windowSide = key

			if (key === 'front_side') {
				const tween = new TWEEN.Tween(this.three.parent.rotation)
					.to({y: THREE.MathUtils.degToRad(45)}, 1500)
					.easing(TWEEN.Easing.Quadratic.Out)
					.start()
			} else if (key === 'back_side') {
				const tween = new TWEEN.Tween(this.three.parent.rotation)
					.to({y: THREE.MathUtils.degToRad(180 + 45)}, 2000)
					.easing(TWEEN.Easing.Quadratic.Out)
					.start()
			}
		},
		changeModelSize: function(windowIndex = 0) {

			if (!this.isValues()) return;

			const is_opened = this.window_opened[windowIndex].v || this.window_opened[windowIndex].h;

			let models = this.static_models

			const range = {
				min: 80,
				max: 150,
			}

			const {width: wWidth, height: wHeight} = this.getValues(windowIndex)

			let width = Math.min(Math.max(wWidth, this.size_values.width.min), this.size_values.width.max) / 2
			let height = Math.min(Math.max(wHeight, this.size_values.height.min), this.size_values.height.max) / 2

			if (window.innerWidth <= 768 && wWidth >= 130) {
				this.three.camera_z_value = 25
				this.three.camera.position.z = this.three.camera_z_value
			} else {
				this.three.camera_z_value = 16 + Math.floor(this.windowCount * 2.5)
				this.three.camera.position.z = this.three.camera_z_value
			}

			if (!is_opened) {
				this.models[windowIndex].inside_group = new THREE.Group()
				this.models[windowIndex].outside_group = new THREE.Group()

				this.models[windowIndex].inside_group.name = `inside_group_${windowIndex}`
				this.models[windowIndex].outside_group.name = `outside_group_${windowIndex}`

				this.three.parent.remove(this.three.scene.getObjectByName('mesh_0'));
				this.three.parent.remove(this.three.scene.getObjectByName('mesh_1'));

				this.models[windowIndex].pivot_global = new THREE.Group()
				this.models[windowIndex].pivot_global.name = `global_pivot_${windowIndex}`

				//position windows on the center
				if (windowIndex === 0) {
					this.models[windowIndex].pivot_global.position.set((width/2 + 11) * -0.1, 0, 0);
				} else {
					this.models[windowIndex].pivot_global.position.set(windowIndex * (width/2 + 11) * 0.1, 0, 0);
				}

				this.three.parent.remove(this.three.parent.getObjectByName( `global_pivot_${windowIndex}`));
				this.three.parent.remove(this.three.parent.getObjectByName(`inside_group_${windowIndex}`));
				this.three.parent.remove(this.three.parent.getObjectByName(`outside_group_${windowIndex}`));

				for (let i = 0; i < models.length; i++) {

					let k = `${models[i].name}_${windowIndex}`

					this.three.parent.remove(this.three.parent.getObjectByName(k));

					const mesh = this.getMesh(windowIndex, k, {
						width: Number(width + 18),
						height: Number(height + 18),
					})

					this.models[windowIndex][k] = mesh;

					this.models[windowIndex][k].scale.set(0.10, 0.10, 0.10)

					this.models[windowIndex][k].position.z = models[i].z

					if (k === `frame_inside_${windowIndex}`) {
						this.models[windowIndex].inside_group.add(this.models[windowIndex][k])
						this.models[windowIndex][k].position.x = -0.15
					} else if (k === `frame_inside_front_${windowIndex}`) {
						this.models[windowIndex].inside_group.add(this.models[windowIndex][k])
						this.models[windowIndex][k].position.x = -0.15
					} else if (k === `frame_outside_colored_${windowIndex}`) {
						this.models[windowIndex].outside_group.add(this.models[windowIndex][k])
					} else if (k === `frame_inside_colored_${windowIndex}`) {
						this.models[windowIndex].outside_group.add(this.models[windowIndex][k])
					} else if (k === `glass_${windowIndex}`) {
						this.models[windowIndex].outside_group.add(this.models[windowIndex][k])
					}
				}
			}

			let s = 1.05
			let z = ((wWidth - this.size_values.width.min) * 0.05) + s * 3

			let hr_s = this.window_values[windowIndex].horizontal_grid.split(".")
			let vr_s = this.window_values[windowIndex].vertical_grid.split(".")

			let hr = hr_s[1] === "none" ? 0 : hr_s[1] === "one" ? 1 : hr_s[1] === "two" ? 2 : 3
			let vr = vr_s[1] === "none" ? 0 : vr_s[1] === "one" ? 1 : vr_s[1] === "two" ? 2 : 3

			let cof;

			if (this.grids_array.indexOf(windowIndex) > -1) {
				for (let f = 0; f < this.grids_array[windowIndex].length; f++) {
					let k = this.grids_array[windowIndex][f].name
					this.models[windowIndex].inside_group.remove(this.three.scene.getObjectByName(k));
				}
			} else {
				this.grids_array[windowIndex] = []
			}

			if (hr !== 0 || vr !== 0) {

				this.grids_array[windowIndex] = []

				let a = (width + 18) - 12
				let b = (height + 18) - 7.5

				for (let j = 1; j <= hr; j++) {
					const vr_g_1 = {
						name: 'grid_horizontal_inside' + j + "_" + windowIndex,
						z: 0.16,
						x: j,
						b: 2.5,
						cof: b / (hr + 1)
					}
					const vr_g_2 = {
						name: 'grid_horizontal_outside' + j + "_" + windowIndex,
						z: 0.04,
						x: j,
						b: 2.5,
						cof: b / (hr + 1)
					}

					this.grids_array[windowIndex].push(vr_g_1)
					this.grids_array[windowIndex].push(vr_g_2)
				}

				for (let g = 1; g <= vr; g++) {
					let hr_g_1 = {
						name: 'grid_vertical_inside' + g  + "_" + windowIndex,
						z: 0.16,
						x: g,
						b: 2.5,
						cof: a / (vr + 1)
					}
					const hr_g_2 = {
						name: 'grid_vertical_outside' + g + "_" + windowIndex,
						z: 0.04,
						x: g,
						b: 2.5,
						cof: a / (vr + 1)
					}
					this.grids_array[windowIndex].push(hr_g_1)
					this.grids_array[windowIndex].push(hr_g_2)
				}

				for (let rn = 0; rn < this.grids_array[windowIndex].length; rn++) {
					let k = this.grids_array[windowIndex][rn].name
					this.models[windowIndex][k] = this.getMesh(windowIndex, k, {
							width: Number(width + 18),
							height: Number(height + 18),
						},
						this.grids_array[windowIndex][rn].cof * this.grids_array[windowIndex][rn].x + this.grids_array[windowIndex][rn].b
					)

					this.models[windowIndex][k].scale.set(0.10, 0.10, 0.10)
					this.models[windowIndex][k].position.z = this.grids_array[windowIndex][rn].z
					this.models[windowIndex].inside_group.add(this.models[windowIndex][k])

					if (k.includes('grid_horizontal_inside') || k.includes('grid_vertical_inside')) {
						this.models[windowIndex][k].material.color.setHex(Number(this.getHex(this.window_values[windowIndex].active_colors.inside)))
					}
					if (k.includes('grid_horizontal_outside') || k.includes('grid_vertical_outside')) {
						this.models[windowIndex][k].material.color.setHex(Number(this.getHex(this.window_values[windowIndex].active_colors.outside)))
					}
				}
			}

			if (wHeight >= range.min && wHeight <= range.max) {
				let k = wHeight / -30

				this.models[windowIndex].handle_model.scale.set(0.013, 0.013, 0.013)

				for (let model of models) {
					this.models[windowIndex][`${model.name}_${windowIndex}`].position.y = k
				}
				for (let model of this.grids_array[windowIndex]) {
					this.models[windowIndex][model.name].position.y = k
				}
			} else if (wHeight < range.min) {
				let k = wHeight / -24

				this.models[windowIndex].handle_model.scale.set(0.013, 0.013, 0.013)

				for (let model of models) {
					this.models[windowIndex][`${model.name}_${windowIndex}`].position.y = k
				}
				for (let model of this.grids_array[windowIndex]) {
					this.models[windowIndex][model.name].position.y = k
				}
			}

			this.models[windowIndex].pivot_global.add(this.models[windowIndex].inside_group);
			this.models[windowIndex].pivot_global.add(this.models[windowIndex].outside_group);

			this.three.parent.rotation.y = THREE.MathUtils.degToRad(45)

			const {camera_delay, camera_factor} = this.getDelayValues('horizontal', windowIndex)

			let z_camera = camera_factor;

			if (camera_factor <= this.three.camera_z_value) {
				z_camera = this.three.camera_z_value
			}

			const camera = new TWEEN.Tween(this.three.camera.position)
				.to({z: Number(z_camera)}, 200)
				.delay(camera_delay)
				.easing(TWEEN.Easing.Linear.None)
				.start()

			this.three.parent.add(this.models[windowIndex].pivot_global)

			for (let item of this.models[windowIndex].inside_group.children) {
				if (item.name.includes('grid_horizontal_inside') || item.name.includes('grid_horizontal_outside') || item.name.includes('grid_vertical_inside') || item.name.includes('grid_vertical_outside')) {
					item.position.x = -wWidth / 33 * 2
				}
			}

			this.handleSide(windowIndex)
		},
		handleSide: function(windowIndex) {
			let s = 1.1

			let model = this.models[windowIndex].handle_model

			const {width: wWidth, height: wHeight, handle_side} = this.getValues(windowIndex)

			if (handle_side === 'handleside.right') {
				model.position.x = ((wWidth - this.size_values.width.min) * 0.05) + s * 3

				this.models[windowIndex].outside_group.position.x = -wWidth / 33
				this.models[windowIndex].inside_group.position.x = -wWidth / 33

				if ((this.window_type === 0 && windowIndex === 0) || (this.window_type === 1 && windowIndex === 1) || this.window_type === 2) {
					this.models[windowIndex].i_g = new THREE.Group()
					this.models[windowIndex].i_g.add(this.models[windowIndex].handle_model)
					this.models[windowIndex].i_g.position.x = 0
					this.models[windowIndex].inside_group.add(this.models[windowIndex].i_g)
				} else {
					const handle_g = this.models[windowIndex].i_g
					if (handle_g && handle_g instanceof THREE.Group) {
						if (handle_g.children.length > 0) {
							handle_g.children.forEach(el => handle_g.remove(el))
						}
					}
				}

				this.models[windowIndex].inside_group.add(this.models[windowIndex][`glass_${windowIndex}`])

				this.models[windowIndex][`frame_inside_${windowIndex}`].position.x = -0.15
				this.models[windowIndex][`frame_inside_front_${windowIndex}`].position.x = -0.15
				this.models[windowIndex][`glass_${windowIndex}`].position.x = -0.15

				for (let item of this.models[windowIndex].inside_group.children) {
					if (item.name.includes('grid_horizontal_inside') || item.name.includes('grid_horizontal_outside') || item.name.includes('grid_vertical_inside') || item.name.includes('grid_vertical_outside')) {
						item.position.x = 0
					}
				}
			} else {
				model.position.x = 0.2
				model.position.y = 0
				if (wHeight <= 50) {
					model.position.y = 0.2
				}

				this.models[windowIndex].outside_group.position.x = -wWidth / 33
				this.models[windowIndex].inside_group.position.x = wWidth / 33

				if ((this.window_type === 0 && windowIndex === 0) || (this.window_type === 1 && windowIndex === 1) || this.window_type === 2) {
					this.models[windowIndex].i_g = new THREE.Group()
					this.models[windowIndex].i_g.add(this.models[windowIndex].handle_model)
					this.models[windowIndex].i_g.position.x = -wWidth / 33 * 2 - 0.15
					this.models[windowIndex].inside_group.add(this.models[windowIndex].i_g)
				} else {
					const handle_g = this.models[windowIndex].i_g

					if (handle_g && handle_g instanceof THREE.Group) {
						if (handle_g.children.length > 0) {
							handle_g.children.forEach(el => handle_g.remove(el))
						}
					}
				}

				this.models[windowIndex].inside_group.add(this.models[windowIndex][`glass_${windowIndex}`])

				this.models[windowIndex][`frame_inside_${windowIndex}`].position.x = -wWidth / 33 * 2
				this.models[windowIndex][`frame_inside_front_${windowIndex}`].position.x = -wWidth / 33 * 2
				this.models[windowIndex][`glass_${windowIndex}`].position.x = -wWidth / 33 * 2

				for (let item of this.models[windowIndex].inside_group.children) {
					if (item.name.includes('grid_horizontal_inside') || item.name.includes('grid_horizontal_outside') || item.name.includes('grid_vertical_inside') || item.name.includes('grid_vertical_outside')) {
						item.position.x = -wWidth / 33 * 2
					}
				}
			}

			return;
		},
		getDelayValues: function(key, windowIndex){

			const w_z = '' + Math.trunc(this.changeWidth)

			const h_z = '' + Math.trunc(this.changeHeight)

			const camera_z = Number(w_z.slice(0, -1)) + Number(h_z.slice(0, -1))

			let camera_factor_z = 0
			let k;

			key === 'horizontal' ? k = 'h' : k = 'v'

			if (window.innerWidth <= 768 && this.changeWidth >= 130) {
				camera_factor_z = 2.5
			} else {
				camera_factor_z = 0
			}

			return {
				handle: (this.window_opened[windowIndex].v || this.window_opened[windowIndex].h) ? 800 : 0,
				frame_inside: !this.window_opened[windowIndex][k] ? 800 : 0,
				camera_factor: !this.window_opened[windowIndex][k] ? (camera_z / 1.2) + camera_factor_z : this.three.camera_z_value,
				camera_delay: !this.window_opened[windowIndex][k] ? 0 : 800,
			}
		},
		doubleOpen: function(key, windowIndex) {

			const _this = this;

			let dnm_deg = 4.5

			let size = new THREE.Vector3();
			new THREE.Box3().setFromObject(this.models[windowIndex][`frame_inside_${windowIndex}`]).getSize(size)

			const sizes = {
				frame_inside: size,
			}

			const {handle: handleDelay} = this.getDelayValues(key, windowIndex)

			if (key === 'vertical') {
				const tween_z = new TWEEN.Tween(this.models[windowIndex].inside_group.position)
					.to({z: 0}, 1000)
					.easing(TWEEN.Easing.Linear.None)
					.start()

				const tween_rr = new TWEEN.Tween(this.models[windowIndex].inside_group.rotation)
					.to({y: THREE.MathUtils.degToRad(0), x: THREE.MathUtils.degToRad(0)}, 1000)
					.easing(TWEEN.Easing.Linear.None)
					.start()

				const tween_1 = new TWEEN.Tween(this.models[windowIndex].inside_group.rotation)
				.to({x: THREE.MathUtils.degToRad(10)}, 1000)
				.easing(TWEEN.Easing.Linear.None)

				const tween_zz = new TWEEN.Tween(this.models[windowIndex].inside_group.position)
					.to({z: this.changeHeight / this.size_values.height.max}, 1000)
					.easing(TWEEN.Easing.Linear.None)
					.onComplete(() => {
						_this.is_slider_disabled = false
						_this.is_select_disabled = true
					})

				for (let h of this.models[windowIndex].handle) {
					if (h.name === "HandleMain" || h.name === "HandleMain_1") {
						const tween = new TWEEN.Tween(h.rotation)
							.to({y: THREE.MathUtils.degToRad(this.window_values[windowIndex].handle_side === "handleside.left" ? 180 : 180 * -1)}, 700)
							.delay(handleDelay)
							.easing(TWEEN.Easing.Linear.None)
							.onComplete(() => {
								tween_1.start()
								tween_zz.start()
							})
							.start()
					}
				}
			} else if (key === 'horizontal') {

				const tween_z1 = new TWEEN.Tween(this.models[windowIndex].inside_group.position)
					.to({z: 0}, 1000)
					.easing(TWEEN.Easing.Linear.None)
					.start()

				const tween_rr1 = new TWEEN.Tween(this.models[windowIndex].inside_group.rotation)
					.to({y: THREE.MathUtils.degToRad(0), x: THREE.MathUtils.degToRad(0)}, 1000)
					.easing(TWEEN.Easing.Linear.None)
					.start()

				const tween_11 = new TWEEN.Tween(this.models[windowIndex].inside_group.rotation)
					.to({y: THREE.MathUtils.degToRad(this.window_values[windowIndex].handle_side === "handleside.left" ? (Number(this.size_values.width.max) + Number(this.changeWidth)) / dnm_deg : -((Number(this.size_values.width.max) + Number(this.changeWidth)) / dnm_deg))}, 1000)
					.easing(TWEEN.Easing.Linear.None)

				const tween_zz1 = new TWEEN.Tween(this.models[windowIndex].inside_group.position)
					.to({z: this.window_values[windowIndex].handle_side === "handleside.left" ? (10.5 - sizes.frame_inside.x) / (Math.PI * 2.5) : (10.5 - sizes.frame_inside.x) / (Math.PI * 7)}, 1000)
					.easing(TWEEN.Easing.Linear.None)
					.onComplete(() => {
						this.is_slider_disabled = false
						this.is_select_disabled = true
					})

				for (let h of this.models[windowIndex].handle) {
					if (h.name === "HandleMain" || h.name === "HandleMain_1") {
						const tween = new TWEEN.Tween(h.rotation)
							.to({y: THREE.MathUtils.degToRad(this.window_values[windowIndex].handle_side === "handleside.left" ? 90 : -90)}, 700)
							.delay(handleDelay)
							.easing(TWEEN.Easing.Linear.None)
							.onComplete(() => {
								tween_11.start()
								tween_zz1.start()
							})
							.start()
					}
				}
			}
		},
		openWindow: function (key, windowIndex) {

			const _this = this

			let dnm_deg = 4.5

			let status = true

			if (window.innerWidth <= 768) {
				this.camera_z_value = 16
			}

			if (window.innerWidth <= 768 && _this.changeWidth >= 130) {
				dnm_deg = 10
			}

			let size = new THREE.Vector3();
			new THREE.Box3().setFromObject(this.models[windowIndex][`frame_inside_${windowIndex}`]).getSize(size)

			const sizes = {
				frame_inside: size,
			}

			const {camera_delay, camera_factor, handle: delayHandle, frame_inside: frameInsideDelay} = this.getDelayValues(key, windowIndex)

			let z_camera = camera_factor;

			if (camera_factor <= this.three.camera_z_value) {
				z_camera = this.three.camera_z_value
			}

			const camera = new TWEEN.Tween(this.three.camera.position)
				.to({z: Number(z_camera)}, 200)
				.delay(camera_delay)
				.easing(TWEEN.Easing.Linear.None)
				.start()

			this.is_slider_disabled = true

			if (key === 'vertical' && this.window_opened[windowIndex].h) {
				this.window_opened[windowIndex].h = false
				this.window_opened[windowIndex].v = true
				this.doubleOpen('vertical', windowIndex)
				return;
			} else if (key === 'horizontal' && this.window_opened[windowIndex].v) {
				this.window_opened[windowIndex].h = true
				this.window_opened[windowIndex].v = false
				this.doubleOpen('horizontal', windowIndex)
				return;
			}

			if (key === 'vertical' && !this.window_opened[windowIndex].v) {
				this.is_select_disabled = true
				sizes.degree_model = 10
				sizes.degree_handle = 180
				sizes.z = this.changeHeight / this.size_values.height.max
			} else if (key === 'vertical' && this.window_opened[windowIndex].v) {
				sizes.degree_model = 0
				sizes.degree_handle = 0
				sizes.z = 0
				this.is_select_disabled = false
			}

			if (key === 'horizontal' && this.window_opened[windowIndex].h) {
				sizes.degree_model = 0
				sizes.degree_handle = 0
				sizes.z = 0
				this.is_select_disabled = false
			} else if (key === 'horizontal' && !this.window_opened[windowIndex].h) {
				sizes.degree_model = this.window_values[windowIndex].handle_side === "handleside.left" ? (Number(this.size_values.width.max) + Number(this.changeWidth)) / dnm_deg : -((Number(this.size_values.width.max) + Number(this.changeWidth)) / dnm_deg)
				sizes.degree_handle = 90
				sizes.z = this.window_values[windowIndex].handle_side === "handleside.left" ? (10.5 - sizes.frame_inside.x) / (Math.PI * 2.25) : (10.5 - sizes.frame_inside.x) / (Math.PI * 7)
				this.is_select_disabled = true
			}

			const handleRotation = this.window_values[windowIndex].handle_side === "handleside.left" ? sizes.degree_handle : sizes.degree_handle * -1

			for (let h of this.models[windowIndex].handle) {
				if (h.name === "HandleMain" || h.name === "HandleMain_1") {
					new TWEEN.Tween(h.rotation)
						.to({y: THREE.MathUtils.degToRad(handleRotation)}, 700)
						.delay(handleRotation ? delayHandle : 2000)
						.easing(TWEEN.Easing.Linear.None)
						.start()
				}
			}

			const tween_z = new TWEEN.Tween(this.models[windowIndex].inside_group.position)
				.to({z: sizes.z}, 1000)
				.delay(frameInsideDelay)
				.easing(TWEEN.Easing.Linear.None)
				.onComplete(() => {
					this.is_slider_disabled = false
				})
				.start()

			if (key === 'horizontal') {
				new TWEEN.Tween(this.models[windowIndex].inside_group.rotation)
				.to({y: THREE.MathUtils.degToRad(sizes.degree_model)}, 1000)
				.delay(frameInsideDelay)
				.easing(TWEEN.Easing.Linear.None)
				.start()
			} else {
				new TWEEN.Tween(this.models[windowIndex].inside_group.rotation)
				.to({x: THREE.MathUtils.degToRad(sizes.degree_model)}, 1000)
				.delay(frameInsideDelay)
				.easing(TWEEN.Easing.Linear.None)
				.start()
			}

			if (key === 'horizontal') {
				this.window_opened[windowIndex].h = !this.window_opened[windowIndex].h
			} else if (key === 'vertical') {
				this.window_opened[windowIndex].v = !this.window_opened[windowIndex].v
			}
		},

		addToCart() {
			let url = this.isAdmin ? '/admin/cart' : '/cart'
			let data = this.getWindowMultipleValues()

            console.log(data);

			axios.post(url, data).then(() => {
				if (!this.isAdmin) {
					document.cookie = "page=" + process.env.MIX_FRONT_APP_URL + "/dobbelt-vindu/"
					window.top.location.href = process.env.MIX_FRONT_APP_URL + "/cart/"
				} else {
					window.location.href = "/admin/cart";
				}
			})
		}
	},
}
</script>
