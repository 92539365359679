<template>
	<div ref="container" class="container-fluid">
		<div v-if="!isAdmin" class="row d-lg-none" id="sticky-anchor">
			<nav class="navbar fixed-top navbar-light bg-light">
				<div class="container-fluid">
					<p>
						Pris {{ totalPrice }} NOK
					</p>
				</div>
			</nav>
		</div>

        <div class="container mt-4">
            <p class="alert alert-info px-4 mb-0">På jakt etter noe spesielt eller trenger bare noen råd eller litt veiledning? Vi er klar for å hjelpe:
                Ring oss direkte på 40 40 35 75, eller kontakt oss på mail: post@kystvinduet.no</p>
        </div>
		<div class="main-window">
			<div class="canvas-wrapper" ref="canvasWrapper">
				<canvas id="window"></canvas>
				<div class='glass-content'>
					<div v-if="changeFTypeGlass.split('.')[1] !== 'none'">
						<img alt='first' v-bind:src="'/img/' + changeFTypeGlass.split('.')[1] + '.png'"/>
					</div>
					<div v-if="changeSTypeGlass.split('.')[1] !== 'standard'">
						<img alt='second' v-bind:src="'/img/' + changeSTypeGlass.split('.')[1] + '.png'"/>
					</div>
				</div>
				<div class="slider">
					<div class="slider-item front-side" v-on:click="changeWindowSide('front_side')"
					     :class="{ disabled : windowSide === 'front_side' }">
						Sett fra innsiden
					</div>
					<div class="slider-item back-side" v-on:click="changeWindowSide('back_side')"
					     :class="{ disabled : windowSide === 'back_side' }">
						Sett fra utsiden
					</div>
				</div>
			</div>
			<div class="forms-wrapper" ref="formsWrapper">
				<h1 class="title">
					Fastvindu
				</h1>
				<p class="price d-none d-lg-block">
					Pris {{ totalPrice }} NOK
				</p>
				<div class="select-values">
                    <p class="alert  form-text" :class="isAdmin ? 'alert-info' : 'alert-primary'">
						{{ changeInventoryId }}
					</p>
					<div class="custom-select-wrapper input">
						<div class="wrapper">
							<p class="text">Antall</p>
							<quantity-input :value="window_values.quantity" v-model="changeQuantity"></quantity-input>
							<a href="#" class="get-answer-btn d-none">?</a>
						</div>
					</div>
					<div class="custom-select-wrapper input">
						<div class="wrapper">
							<p class="text">Bredde, cm</p>
							<select class="custom-select" v-model="changeWidth" id="changeWidth"
							        v-bind:disabled="is_select_disabled">
                                <option value='30'>30 (29 cm standard)</option>
                                <option value='40'>40 (39 cm standard)</option>
								<option value='50'>50 (49 cm standard)</option>
								<option value='60'>60 (59 cm standard)</option>
								<option value='70'>70 (69 cm standard)</option>
								<option value='80'>80 (79 cm standard)</option>
								<option value='90'>90 (89 cm standard)</option>
								<option value='100'>100 (99 cm standard)</option>
								<option value='110'>110 (109 cm standard)</option>
								<option value='120'>120 (119 cm standard)</option>
								<option value='130'>130 (129 cm standard)</option>
								<option value='140'>140 (139 cm standard)</option>
								<option value='150'>150 (149 cm standard)</option>
								<option value='160'>160 (159 cm standard)</option>
								<option value='170'>170 (169 cm standard)</option>
								<option value='180'>180 (179 cm standard)</option>
								<option value='190'>190 (189 cm standard)</option>
								<option value='200'>200 (199 cm standard)</option>
								<option value='210'>210 (209 cm standard)</option>
								<option value='220'>220 (219 cm standard)</option>
								<option value='230'>230 (229 cm standard)</option>
								<option value='240'>240 (239 cm standard)</option>
								<option value='250'>250 (249 cm standard)</option>
								<option value='260'>260 (259 cm standard)</option>
							</select>
							<a href="#" class="get-answer-btn d-none">?</a>
						</div>
						<p class="text-alert" v-if="changeWidth < getMinWidth(sizePrices)">Min
							{{ getMinWidth(sizePrices) }} cm
						</p>
						<p class="text-alert" v-if="changeWidth > getMaxWidth(sizePrices)">Max
							{{ getMaxWidth(sizePrices) }} cm
						</p>
					</div>
					<div class="custom-select-wrapper input">
						<div class="wrapper">
							<p class="text">Høyde, cm</p>
							<select class="custom-select" v-model="changeHeight" id="changeHeight"
							        v-bind:disabled="is_select_disabled">
                                <option value='30'>30 (29 cm standard)</option>
                                <option value='40'>40 (39 cm standard)</option>
								<option value='50'>50 (49 cm standard)</option>
								<option value='60'>60 (59 cm standard)</option>
								<option value='70'>70 (69 cm standard)</option>
								<option value='80'>80 (79 cm standard)</option>
								<option value='90'>90 (89 cm standard)</option>
								<option value='100'>100 (99 cm standard)</option>
								<option value='110'>110 (109 cm standard)</option>
								<option value='120'>120 (119 cm standard)</option>
								<option value='130'>130 (129 cm standard)</option>
								<option value='140'>140 (139 cm standard)</option>
								<option value='150'>150 (149 cm standard)</option>
								<option value='160'>160 (159 cm standard)</option>
								<option value='170'>170 (169 cm standard)</option>
								<option value='180'>180 (179 cm standard)</option>
								<option value='190'>190 (189 cm standard)</option>
								<option value='200'>200 (199 cm standard)</option>
								<option value='210'>210 (209 cm standard)</option>
								<option value='220'>220 (219 cm standard)</option>
								<option value='230'>230 (229 cm standard)</option>
								<option value='240'>240 (239 cm standard)</option>
							</select>
							<a href="#" class="get-answer-btn d-none">?</a>
						</div>
						<p class="text-alert" v-if="changeHeight < getMinHeight(sizePrices)">Min
							{{ getMinHeight(sizePrices) }} cm
						</p>
						<p class="text-alert" v-if="changeHeight > getMaxHeight(sizePrices)">Max
							{{ getMaxHeight(sizePrices) }} cm
						</p>
					</div>
                    <div class="custom-select-wrapper">
                        <p class="text">Farge utside</p>

                        <select class="custom-select" v-model="changeOutsideFrameColor">
                            <option v-for="feature in getFeatureList('frameoutside')" :value='feature.code'>
                                {{ feature.name }}
                            </option>
                        </select>
                        <a href="#" class="get-answer-btn d-none">?</a>
                    </div>
					<div class="custom-select-wrapper">
						<p class="text">Farge innside</p>
						<select class="custom-select" v-model="changeInsideFrameColor" id="changeInsideFrameColor">
							<option v-for="feature in getFeatureList('frameinside')" :value='feature.code'>
								{{ feature.name }}
							</option>
						</select>
						<a href="#" class="get-answer-btn d-none">?</a>
					</div>
					<div class="custom-select-wrapper">
						<p class="text">Isolasjonsgrad</p>
						<select class="custom-select" id="isolation" v-model="changeIsolation">
							<option v-for="feature in getFeatureList('isolation')" :value='feature.code'>
								{{ feature.name }}
							</option>
						</select>
						<a href="#" class="get-answer-btn d-none">?</a>
					</div>
					<div class="custom-select-wrapper">
						<p class="text">Type glass</p>
						<div style="flex-grow: 1; display: flex; flex-direction: column;">
							<select class="custom-select" id="firstglass" style="width: 100%;"
							        v-model="changeFTypeGlass">
								<option v-for="feature in getFeatureList('firstglass')" :value='feature.code'>
									{{ feature.name }}
								</option>
							</select>
							<select class="custom-select" id="secondglass" style="width: 100%;"
							        v-model="changeSTypeGlass">
								<option v-for="feature in getFeatureList('secondglass')" :value='feature.code'>
									{{ feature.name }}
								</option>
							</select>
						</div>
						<a href="#" class="get-answer-btn d-none">?</a>
					</div>
					<div class="custom-select-wrapper">
						<p class="text">Notspor</p>
						<select class="custom-select" v-model="changeNotspor">
							<option v-for="feature in getFeatureList('notspor')" :value='feature.code'>
								{{ feature.name }}
							</option>
						</select>
						<a href="#" class="get-answer-btn d-none">?</a>
					</div>
					<div class="custom-select-wrapper">
						<p class="text">Sprosser horisontalt</p>
						<select class="custom-select" id="changeHorizontalGrid" v-model="changeHorizontalGrid">
							<option v-for="feature in getFeatureList('horizontalgrid')" :value='feature.code'>
								{{ feature.name }}
							</option>
						</select>
						<a href="#" class="get-answer-btn d-none">?</a>
					</div>
					<div class="custom-select-wrapper">
						<p class="text">Sprosser vertikalt</p>
						<select class="custom-select" v-model="changeVerticalGrid">
							<option v-for="feature in getFeatureList('verticalgrid')" :value='feature.code'>
								{{ feature.name }}
							</option>
						</select>
						<a href="#" class="get-answer-btn d-none">?</a>
					</div>
					<template v-if="isAdmin">
						<div class="button-wrapper">
							<a href="#" @click.prevent="showInventoryModal()" class="add-to-cart">Lag nytt produkt for lagerbeholdning</a>
						</div>
						<div class="button-wrapper">
							<a href="#" class="add-to-cart" :class="{ 'disabled' : !cartId }" @click.prevent="addToCart">Legg til rabatt, kosttillegg, frakt og lag tilbud</a>
						</div>
						<div class="button-wrapper">
							<a href="#" @click.prevent="showCustomProductModal()" class="add-to-cart" > Lag ny produktlenke med dette oppsettet</a>
						</div>
						<div class="button-wrapper">
							<a href="/admin/cart" :class="{ 'disabled' : !cartId }" class="add-to-cart">Gå til handlekurv</a>
						</div>
					</template>
					<template v-else>
						<div class="button-wrapper">
							<a href="#" class="add-to-cart" @click.prevent="addToCart">Legg i handlekurv</a>
						</div>

						<div v-if="cartId" class="button-wrapper"><a href="cart" class="add-to-cart">Gå til handlekurv</a>
						</div>
					</template>
				</div>
			</div>
		</div>
		<add-inventory-modal v-if="isAdmin" :window_values="window_values" type="window-fixed" ref="inventoryModal"></add-inventory-modal>
		<create-custom-product-modal v-if="isAdmin" :window_values="window_values" type="window-fixed" ref="customProductModal"></create-custom-product-modal>
	</div>
</template>

<script>
import GeneralMixin from "../Mixins/GeneralMixin";
import * as THREE from "three";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import WindowValuesMixin from "../Mixins/WindowValuesMixin";

const TWEEN = require('@tweenjs/tween.js')

const loader = new GLTFLoader();
export default {
	mixins: [
		GeneralMixin,
		WindowValuesMixin
	],

	data() {
		return {
			grids_array: [],
			colors: [{
				text: 'hvit',
				hex: '0xa6a6a6',
			},
				{
					text: 'Grå',
					hex: '0x666666'
				},
				{
					text: 'Mørk brun',
					hex: '0x33230b'
				},
				{
					text: 'Svart',
					hex: '0x1c1c1a'
				},
			],
			grid_colors: [{
				text: 'hvit',
				hex: '0xa6a6a6',
			},
				{
					text: 'Grå',
					hex: '0x666666'
				},
				{
					text: 'Mørk brun',
					hex: '0x33230b'
				},
				{
					text: 'Svart',
					hex: '0x1c1c1a'
				},
			],
			color_price: {
				outside: {
					white: 0,
					gray: 0,
					brown: 0,
					black: 0,
				},
				inside: {
					white: 0,
					gray: 0,
					brown: 0,
					black: 0,
				},
				bothsides: {
					white: 0,
					gray: 0,
					brown: 0,
					black: 0,
				},
			},
			handle_colors: [
				{
					text: 'hvit',
					hex: '0xffffff',
					price: 0,
				},
				{
					text: 'Grå',
					hex: '0xc9c9c9',
					price: 0,
				},
				{
					text: 'sølv',
					hex: '0xc9c9c9',
					price: 0,
				},
				{
					text: 'Svart',
					hex: '0x3d3d3d',
					price: 0,
				},
			],
			size_values: {
				width: {
					min: 30.0,
					max: 260.0,
				},
				height: {
					min: 30.0,
					max: 240.0,
				},
			},
			type_of_glass: {
				first_type: [{
					text: 'Ingen sikkerhetsglass',
					price: 0,
				},
					{
						text: 'Sikkerhetsglass innside',
						price: 0,
					},
					{
						text: 'Sikkerhetsglass utside',
						price: 0,
					},
					{
						text: 'Sikkerhetsglass begge sider',
						price: 0,
					},
				],
				second_type: [
					{
						text: 'Standard glass',
						price: 0,
					},
					{
						text: 'Frosta glass',
						price: 0,
					},
					{
						text: 'Soldempende glass',
						price: 0,
					},
					{
						text: 'tonet glass',
						price: 0,
					},


				],
			},
			notspor: [
				{
					text: '0mm',
					price: 0
				},
				{
					text: '10mm',
					price: 0,
				},
				{
					text: '13mm',
					price: 0,
				},
			],
			childsafe: [
				{
					text: 'Nei',
					price: 0
				},
				{
					text: 'Ja',
					price: 0,
				},
			],
			grids: {
				price_add: {
					default: 0,
					not_default: 0,
				},
				horizontal: {
					type: 'horizontal',
					values: [0, 1, 2, 3],
					table: {
						0: [0, 2, 3, 4],
						1: [2, 4, 6, 8],
						2: [3, 6, 9, 12],
						3: [4, 8, 12, 16],
					},
					active_value: null,
				},
				vertical: {
					type: 'vertical',
					values: [0, 1, 2, 3],
					active_value: null,
				}
			},
			core_table: {},
			degree_isolation: {
				values: [
					{
						text: '2-lags'
					},
					{
						text: '3-lags',
						add_c: 0,
					}
				]
			},
			window_values: {
				inventory_id: 0,
				inventory_stock: 0,
				quantity: 1,
				width: 100,
				height: 100,
				active_handle_colors: null,
				notspor: null,
				childsafe: null,
				handle_side: null,
				active_colors: {
					inside: '',
					outside: '',
					grid_inside: '',
					grid_outside: '',
				},
				active_type_of_glass: {
					first: null,
					second: null,
				},
				vertical_grid: null,
				horizontal_grid: null,
				grid_color: null,
				active_isolation: null,
				total_price: 0,
			},
			three: {},
			exchange_rate: 0,
			rebate: 0,
			margin: 0,
			tax: 0,
			window_opened: {
				h: false,
				v: false
			},
			static_models: [
				{
					name: 'frame_inside',
					z: 0.17
				},
				{
					name: 'frame_inside_front',
					z: 0.02
				},
				{
					name: 'frame_outside_colored',
					z: -0.12
				},
				{
					name: 'frame_inside_colored',
					z: 0
				},
				{
					name: 'glass',
					z: 0.16
				}
			],
			is_select_disabled: false,
			is_slider_disabled: false,
		}
	},

	computed: {
		totalPrice: function () {
			if (this.isValues()) {
				return (this.changeQuantity * this.window_values.total_price).toFixed(2)
			}
			return (this.changeQuantity * Math.round(this.window_values.total_price * this.exchange_rate)).toFixed(2)
		},

		windowOpenedH: function () {
			return this.window_opened.h ? 'Lukk vindu' : 'Åpne helt'
		},
		windowOpenedV: function () {
			return this.window_opened.v ? 'Lukk vindu' : 'Åpne i topp'
		},
		changeInventoryId: function () {
			let _this = this
			if (this.isValues()) {
				var jqxhr = $.post("/inventory", {
					changeWidth: this.window_values.width,
					changeHeight: this.window_values.height,
					changeIsolation: this.window_values.active_isolation,
					changeFTypeGlass: this.window_values.active_type_of_glass.first,
					changeSTypeGlass: this.window_values.active_type_of_glass.second,
					changeHorizontalGrid: this.window_values.horizontal_grid,
					changeVerticalGrid: this.window_values.vertical_grid,
					changeInsideFrameColor: this.window_values.active_colors.inside,
					changeOutsideFrameColor: this.window_values.active_colors.outside,
					changeHandleColor: this.window_values.active_handle_colors,
					changeNotspor: this.window_values.notspor,
					changeChildsafe: this.window_values.childsafe,
					type: 'window fixed'
				}, function (data) {
					_this.window_values.inventory_id = data['id'];
					_this.window_values.inventory_stock = data['quantity'];
					_this.window_values.total_price = data['price'];
				})
			}
			return this.window_values.inventory_stock < this.window_values.quantity ? 'LEVERINGSTID 3-4 UKER ' : this.window_values.inventory_stock + ' på lager. Sendes innen 1-3 dager';
		},
		changeQuantity: {
			get: function () {
				return this.window_values.quantity
			},
			set: function (value) {
				this.window_values.quantity = value
			}
		},
		changeInventoryStatus: {
			get: function () {
				return this.window_values.inventory_status
			},
			set: function (value) {
				this.window_values.inventory_status = value
			}
		},
		changeWidth: {
			get: function () {
				return this.window_values.width
			},
			set: function (value) {
				this.window_values.width = value
				if (Number(value) >= this.size_values.width.min && Number(value) <= this.size_values.width.max) {
					this.changeModelSize()
				}
			}
		},
		changeHeight: {
			get: function () {
				return this.window_values.height
			},
			set: function (value) {
				this.window_values.height = value
				if (Number(value) >= this.size_values.height.min && Number(value) <= this.size_values
					.height.max) {
					this.changeModelSize()
				}
			}
		},
		changeVerticalGrid: {
			get: function () {
				return this.window_values.vertical_grid
			},
			set: function (data) {
				this.window_values.vertical_grid = data
				this.changeModelSize()
			}
		},
		changeFTypeGlass: {
			get: function () {
				console.log("changeFTypeGlass")
				return this.window_values.active_type_of_glass.first
			},
			set: function (data) {
				console.log("changeFTypeGlass")
				this.window_values.active_type_of_glass.first = data
			}
		},
		changeSTypeGlass: {
			get: function () {
				return this.window_values.active_type_of_glass.second
			},
			set: function (data) {
				if (data.split(".")[1] === "sunprotect") {
					this.three.material_glass.color.setHex(Number(0x010101))
					this.three.material_glass.opacity = 0.1;
				} else if (data.split(".")[1] === "toned") {
					this.three.material_glass.color.setHex(Number(0xd2b48c))
					this.three.material_glass.opacity = 0.2;
				} else if (data.split(".")[1] === "standard") {
					this.three.material_glass.color.setHex(Number(0x7fb3aa))
					this.three.material_glass.opacity = 0.2;
				} else if (data.split(".")[1] === "frosted") {
					this.three.material_glass.color.setHex(Number(0x7fb3aa))
					this.three.material_glass.opacity = 0.2;
					console.log(this.three.material_glass)
				}

				this.window_values.active_type_of_glass.second = data
			}
		},
		changeIsolation: {
			get: function () {
				return this.window_values.active_isolation
			},
			set: function (data) {
				this.window_values.active_isolation = data
			}
		},
		changeHorizontalGrid: {
			get: function () {
				return this.window_values.horizontal_grid
			},
			set: function (data) {
				this.window_values.horizontal_grid = data
				this.changeModelSize()
			}
		},
		changeInsideFrameColor: {
			get: function () {
				return this.window_values.active_colors.inside
			},
			set: function (text) {
				this.window_values.active_colors.inside = text
				this.window_values.active_colors.grid_inside = text
				this.three.frame_inside.material.color.set(Number(this.getHex(text)))
				for (let rn = 0; rn < this.grids_array.length; rn++) {
					let k = this.grids_array[rn].name
					if (k.includes('grid_horizontal_inside') || k.includes('grid_vertical_inside')) {
						this.three[k].material.color.setHex(Number(this.getHex(text)))
					}
				}
                if(text !== 'frameinside.white'){
                    this.changeOutsideFrameColor = text.replace('frameinside', 'frameoutside');
                }
			}
		},
		changeOutsideFrameColor: {
			get: function () {
				return this.window_values.active_colors.outside
			},
			set: function (text) {
				this.window_values.active_colors.outside = text
				this.window_values.active_colors.grid_outside = text
				this.three.frame_outside_colored.material.color.setHex(Number(this.getHex(this.window_values.active_colors.outside)))
				for (let rn = 0; rn < this.grids_array.length; rn++) {
					let k = this.grids_array[rn].name
					if (k.includes('grid_horizontal_outside') || k.includes('grid_vertical_outside')) {
						this.three[k].material.color.setHex(Number(this.getHex(text)))
					}
				}
                if(this.changeInsideFrameColor !== text.replace('frameoutside', 'frameinside')) {
                    this.changeInsideFrameColor = text.replace('frameoutside', 'frameinside')
                    document.querySelectorAll("#changeInsideFrameColor option").forEach(opt => {
                        opt.disabled = (opt.value !== text.replace('frameoutside', 'frameinside')) && (opt.value !== 'frameinside.white');
                    })
                }
			}
		},
		changeHandleColor: {
			get: function () {
				return this.window_values.active_handle_colors
			},
			set: function (text) {
				for (let c = 0; c < this.three.handle.length; c++) {
					this.three.handle[c].material.color.set(Number(this.getHex(text)))
				}
				this.window_values.active_handle_colors = text
			}
		},
		changeNotspor: {
			get: function () {
				return this.window_values.notspor
			},
			set: function (text) {
				this.window_values.notspor = text
			}
		},
		changeChildsafe: {
			get: function () {
				return this.window_values.childsafe
			},
			set: function (text) {
				this.window_values.childsafe = text
			}
		},

	},

	created() {
		if (this.variation) {
			this.window_values.width = this.variation['width']
			this.window_values.height = this.variation['height']
			this.window_values.active_colors.inside = this.variation['insideFrameColor'] ?? ''
			this.window_values.active_colors.outside = this.variation['outsideFrameColor'] ?? ''
			this.window_values.active_colors.grid_inside = this.variation['insideFrameColor'] ?? ''
			this.window_values.active_handle_colors = this.variation['handleColor'] ?? ''
			this.window_values.notspor = this.variation['notspor'] ?? ''
			this.window_values.horizontal_grid = this.variation['horizontalGrid'] ?? ''
			this.window_values.vertical_grid = this.variation['verticalGrid'] ?? ''
			this.window_values.active_type_of_glass.first = this.variation['fTypeGlass'] ?? ''
			this.window_values.active_type_of_glass.second = this.variation['sTypeGlass'] ?? ''
			this.window_values.lockcylinder = this.variation['lockCylinder'] ?? ''
			this.window_values.threshold = this.variation['threshold'] ?? ''
			this.window_values.brystning = this.variation['brystning'] ?? ''
			this.window_values.slarentning = this.variation['openingDirection'] ?? ''
			this.window_values.active_isolation = this.variation['isolation'] ?? ''
			this.window_values.childsafe = this.variation['childsafe'] ?? ''
			this.window_values.active_isolation = this.variation['isolation'] ?? ''
			this.window_values.grid_color = this.variation['insideFrameColor'] ?? ''

		} else {
			this.window_values.active_colors.inside = 'frameinside.white'
			this.window_values.active_colors.outside = 'frameoutside.white'
			this.window_values.active_colors.grid_inside = this.window_values.active_colors.inside
			this.window_values.active_handle_colors = 'handlecolor.white'
			this.window_values.notspor = 'notspor.ten'
			this.window_values.childsafe = 'childsafe.no'
			this.window_values.horizontal_grid = 'horizontalgrid.none'
			this.window_values.vertical_grid = 'verticalgrid.none'
			this.window_values.active_type_of_glass.first = 'firstglass.none'
			this.window_values.active_type_of_glass.second = 'secondglass.standard'
			this.window_values.active_isolation = 'isolation.two-layers'
			this.window_values.grid_color = 'gridcolor.white'
		}

		this.three.pivot_global = new THREE.Group()
		this.three.pivot_local = new THREE.Group()
	},

	mounted() {
		this.init();
	},

	methods: {
		createModel: function () {
			let _this = this

			this.three.inside = new THREE.Shape();
			this.three.outside = new THREE.Shape();
			this.three.hole = new THREE.Path();

			this.three.material_outside = new THREE.MeshPhongMaterial({
				color: Number(this.getHex(this.window_values.active_colors.outside)),
				specular: 0xffffff,
				shininess: 0,
				depthWrite: true
			});
			this.three.material_outside_colored = new THREE.MeshPhongMaterial({
				color: Number(this.getHex(this.window_values.active_colors.outside)),
				specular: 0xffffff,
				shininess: 0,
			});
			this.three.material_inside = new THREE.MeshPhongMaterial({
				color: Number(this.getHex(this.window_values.active_colors.inside)),
				specular: 0xffffff,
				shininess: 0,
			});
			this.three.material_inside_colored = new THREE.MeshPhongMaterial({
				color: Number(this.getHex(this.window_values.active_colors.inside)),
				specular: 0xffffff,
				shininess: 0,
			});
			this.three.material_grid = new THREE.MeshPhongMaterial({
				color: Number(this.getHex(this.window_values.active_colors.inside)),
				shininess: 0,
			});
			this.three.material_grid_colored = new THREE.MeshPhongMaterial({
				color: Number(0xffffff),
				shininess: 0,
			});

			this.three.material_glass = new THREE.MeshPhongMaterial({
				color: Number(0x7fb3aa),
				shininess: 0,
				transparent: true,
				opacity: 0.2,
				needsUpdate: true,
			});

			this.three.default_settings = {
				depth: 1,
				bevelEnabled: false,
				steps: 1,
				bevelSegments: 2,
				bevelSize: 1,
				bevelThickness: 1
			};

			this.three.inside_settings = {
				depth: 0.6,
				bevelEnabled: true,
				bevelSegments: 2,
				bevelSize: 1,
				bevelThickness: 1
			};

			this.three.outside_settings = {
				depth: 1,
				bevelEnabled: true,
				bevelSegments: 2,
				bevelSize: 1,
				bevelThickness: 1
			};

			this.three.extrudeSettingsCustom = {
				depth: 0.01,
				bevelEnabled: false,
				bevelSegments: 1,
				bevelSize: 1,
				bevelThickness: 1
			};

			let handle = [];
			loader.load('/img/hand_model_2.glb', function (gltf) {
				_this.three.handle = null
				gltf.scene.traverse(function (child) {
					if (child.material) {
						handle.push(child)
						child.material.metalness = 0.35
						child.material.roughness = 0
						child.material.color.setHex(0xffffff)
					}
				});

				const model = gltf.scene
				_this.three.handle = handle
				model.scale.x = 0.013
				model.scale.y = 0.013
				model.scale.z = 0.013

				model.position.y = 0
				model.position.z = 0.35

				_this.three.scene.add(model);
				_this.three.handle_model = model

				model.rotation.x = THREE.Math.degToRad(90)
				model.rotation.y = THREE.Math.degToRad(270)
				_this.changeModelSize()
			})
		},

		getMesh: function (name, size, grids_config) {
			const grid_depth = Math.PI / 3

			if (name === 'frame_inside') {
				this.three.inside = new THREE.Shape();
				this.three.hole = new THREE.Path();
				this.f_rect(this.three.inside, 0, 0, size.width, size.height, 1);
				this.f_rect_reverse(this.three.hole, 2, 2, size.width - 4, size.height - 4, 1);
				this.three.inside.holes.push(this.three.hole);
				let geometry_inside = new THREE.ExtrudeBufferGeometry(this.three.inside, this.three
					.inside_settings);
				let mesh = new THREE.Mesh(geometry_inside, this.three.material_inside);
				mesh.name = name
				return mesh
			} else if (name === 'frame_inside_front') {
				this.three.inside = new THREE.Shape();
				this.three.hole = new THREE.Path();
				this.f_rect(this.three.inside, 0, 0, size.width, size.height, 1);
				this.f_rect_reverse(this.three.hole, 2, 2, size.width - 4, size.height - 4, 1);
				this.three.inside.holes.push(this.three.hole);
				let geometry_inside = new THREE.ExtrudeBufferGeometry(this.three.inside, this.three
					.inside_settings);
				let mesh = new THREE.Mesh(geometry_inside, this.three.material_outside_colored);
				mesh.name = name
				return mesh
			} else if (name === 'frame_outside') {
				this.three.outside = new THREE.Shape();
				this.three.hole = new THREE.Path();
				this.f_rect(this.three.outside, -4.25, -2.5, size.width + 5, size.height + 5, 1);
				this.f_rect_reverse(this.three.hole, Math.PI / 2, Math.PI, size.width - Math.PI * 2, size.height - Math.PI * 2, 1);
				this.three.outside.holes.push(this.three.hole);
				let geometry_outside = new THREE.ExtrudeBufferGeometry(this.three.outside, this.three
					.default_settings);
				let mesh = new THREE.Mesh(geometry_outside, this.three.material_outside);
				mesh.name = name
				return mesh
			} else if (name === 'frame_outside_colored') {
				this.three.outside = new THREE.Shape();
				this.three.hole = new THREE.Path();
				this.f_rect(this.three.outside, -4.25, -2.5, size.width + 5, size.height + 5, 1);
				this.f_rect_reverse(this.three.hole, Math.PI / 2, Math.PI, size.width - Math.PI * 2, size.height - Math.PI * 2, 1);
				this.three.outside.holes.push(this.three.hole);
				let geometry_outside = new THREE.ExtrudeBufferGeometry(this.three.outside, this.three
					.outside_settings);
				let mesh = new THREE.Mesh(geometry_outside, this.three.material_outside_colored);
				mesh.name = name
				return mesh
			} else if (name === 'frame_inside_colored') {
				this.three.outside = new THREE.Shape();
				this.three.hole = new THREE.Path();
				this.f_rect(this.three.outside, -4.25, -2.5, size.width + 5, size.height + 5, 1);
				this.f_rect_reverse(this.three.hole, Math.PI / 2, Math.PI, size.width - Math.PI * 2, size.height - Math.PI * 2, 1);
				this.three.outside.holes.push(this.three.hole);
				let geometry_outside = new THREE.ExtrudeBufferGeometry(this.three.outside, this.three
					.outside_settings);
				let mesh = new THREE.Mesh(geometry_outside, this.three.material_inside);
				mesh.name = name
				return mesh
			} else if (name === 'glass') {
				this.three.outside = new THREE.Shape();
				this.f_rect(this.three.outside, 2, 2, size.width - 4, size.height - 4, 1);
				let geometry_glass = new THREE.ExtrudeBufferGeometry(this.three.outside, this.three
					.extrudeSettingsCustom);
				let mesh = new THREE.Mesh(geometry_glass, this.three.material_glass);
				mesh.name = name
				return mesh
			}

			if (name.includes('grid_vertical_outside')) {
				this.three.inside = new THREE.Shape();
				this.three.hole = new THREE.Path();
				this.f_rect(this.three.inside, grids_config, 0, 2, size.height, 1);

				const extrudeSettings = {
					steps: 1,
					depth: grid_depth,
					bevelEnabled: false,
					bevelThickness: 1,
					bevelSize: 0,
					bevelOffset: 1,
					bevelSegments: 0
				};

				let geometry_inside = new THREE.ExtrudeBufferGeometry(this.three.inside, extrudeSettings);
				let mesh = new THREE.Mesh(geometry_inside, this.three.material_grid_colored);
				mesh.material.color.setHex(Number(0x0ff000))
				mesh.name = name
				return mesh
			} else if (name.includes('grid_horizontal_outside')) {
				this.three.inside = new THREE.Shape();
				this.three.hole = new THREE.Path();
				this.f_rect(this.three.inside, 2, grids_config, size.width - 4, 2, 1);

				const extrudeSettings = {
					steps: 1,
					depth: grid_depth,
					bevelEnabled: false,
					bevelThickness: 1,
					bevelSize: 0,
					bevelOffset: 1,
					bevelSegments: 0
				};

				let geometry_inside = new THREE.ExtrudeBufferGeometry(this.three.inside, extrudeSettings);
				let mesh = new THREE.Mesh(geometry_inside, this.three.material_grid_colored);
				mesh.name = name
				return mesh
			}

			if (name.includes('grid_vertical_inside')) {
				this.three.inside = new THREE.Shape();
				this.three.hole = new THREE.Path();
				this.f_rect(this.three.inside, grids_config, 0, 2, size.height, 1);

				const extrudeSettings = {
					steps: 1,
					depth: grid_depth,
					bevelEnabled: false,
					bevelThickness: 1,
					bevelSize: 0,
					bevelOffset: 1,
					bevelSegments: 0
				};

				let geometry_inside = new THREE.ExtrudeBufferGeometry(this.three.inside, extrudeSettings);
				let mesh = new THREE.Mesh(geometry_inside, this.three.material_grid);
				mesh.name = name
				return mesh
			} else if (name.includes('grid_horizontal_inside')) {
				this.three.inside = new THREE.Shape();
				this.three.hole = new THREE.Path();
				this.f_rect(this.three.inside, 0, grids_config, size.width - 5, 2, 1);

				const extrudeSettings = {
					steps: 1,
					depth: grid_depth,
					bevelEnabled: false,
					bevelThickness: 1,
					bevelSize: 0,
					bevelOffset: 1,
					bevelSegments: 0
				};

				let geometry_inside = new THREE.ExtrudeBufferGeometry(this.three.inside, extrudeSettings);
				let mesh = new THREE.Mesh(geometry_inside, this.three.material_grid);
				mesh.name = name
				return mesh
			}
		},

		changeModelSize: function (type) {
			if (!this.isValues()) return;

			const _this = this;

			const is_opened = this.window_opened.v || this.window_opened.h;

			let models = this.static_models;

			const range = {
				min: 80,
				max: 260,
			}
			if (window.innerWidth <= 768 && this.changeWidth >= 130) {
				this.three.camera_z_value = 20
				this.three.camera.position.z = this.three.camera_z_value
			} else {
				this.three.camera_z_value = 14
				this.three.camera.position.z = this.three.camera_z_value
			}
			if (window.innerWidth > 768 && this.changeWidth >= 130) {
				this.three.camera_z_value = 20
				this.three.camera.position.z = this.three.camera_z_value
			}

			if (!is_opened) {
				this.three.inside_group = new THREE.Group()
				this.three.outside_group = new THREE.Group()

				this.three.inside_group.name = 'inside_group'
				this.three.outside_group.name = 'outside_group'
				_this.three.scene.remove(_this.three.scene.getObjectByName('mesh_0'));
				_this.three.scene.remove(_this.three.scene.getObjectByName('mesh_1'));


				this.three.pivot_global = new THREE.Group()
				this.three.pivot_global.name = 'global_pivot'

				this.three.pivot_global.rotation.y = THREE.Math.degToRad(40)

				this.three.pivot_global.position.set(0, 0, 0);

				_this.three.scene.remove(_this.three.scene.getObjectByName('global_pivot'));
				_this.three.scene.remove(_this.three.scene.getObjectByName('inside_group'));
				_this.three.scene.remove(_this.three.scene.getObjectByName('outside_group'));

			}

			let width = Math.min(Math.max(this.changeWidth, this.size_values.width.min), this.size_values.width.max) / 2
			let height = Math.min(Math.max(this.changeHeight, this.size_values.height.min), this.size_values.height.max) / 2
			let s = 1.05
			let z = ((this.changeWidth - this.size_values.width.min) * 0.05) + s * 3

			let hr_s = this.window_values.horizontal_grid.split(".")
			let vr_s = this.window_values.vertical_grid.split(".")

			let hr = hr_s[1] === "none" ? 0 : hr_s[1] === "one" ? 1 : hr_s[1] === "two" ? 2 : 3
			let vr = vr_s[1] === "none" ? 0 : vr_s[1] === "one" ? 1 : vr_s[1] === "two" ? 2 : 3

			let cof;
			if (is_opened || true) {
				for (let f = 0; f < this.grids_array.length; f++) {

					let k = this.grids_array[f].name

					this.three.inside_group.remove(this.three.scene.getObjectByName(k));
				}

				if (hr !== 0 || vr !== 0) {

					this.grids_array = []

					let a = (width + 18) - 12
					let b = (height + 18) - 7.5

					for (let j = 1; j <= hr; j++) {
						const vr_g_1 = {
							name: 'grid_horizontal_inside' + j,
							z: 0.16,
							x: j,
							b: 2.5,
							cof: b / (hr + 1)
						}
						const vr_g_2 = {
							name: 'grid_horizontal_outside' + j,
							z: 0.04,
							x: j,
							b: 2.5,
							cof: b / (hr + 1)
						}

						this.grids_array.push(vr_g_1)
						this.grids_array.push(vr_g_2)
					}

					for (let g = 1; g <= vr; g++) {
						let hr_g_1 = {
							name: 'grid_vertical_inside' + g,
							z: 0.16,
							x: g,
							b: 2.5,
							cof: a / (vr + 1)
						}
						const hr_g_2 = {
							name: 'grid_vertical_outside' + g,
							z: 0.04,
							x: g,
							b: 2.5,
							cof: a / (vr + 1)
						}
						this.grids_array.push(hr_g_1)
						this.grids_array.push(hr_g_2)
					}

					for (let rn = 0; rn < this.grids_array.length; rn++) {
						let k = this.grids_array[rn].name
						this.three[k] = this.getMesh(k, {
								width: Number(width + 18),
								height: Number(height + 18),
							},
							this.grids_array[rn].cof * this.grids_array[rn].x + this.grids_array[rn].b
						)

						this.three[k].scale.set(0.10, 0.10, 0.10)
						this.three[k].position.z = this.grids_array[rn].z
						this.three.inside_group.add(this.three[k])

						if (k.includes('grid_horizontal_inside') || k.includes('grid_vertical_inside')) {

							this.three[k].material.color.setHex(Number(this.getHex(this.window_values.active_colors.inside)))
						}
						if (k.includes('grid_horizontal_outside') || k.includes('grid_vertical_outside')) {
							this.three[k].material.color.setHex(Number(this.getHex(this.window_values.active_colors.outside)))
						}
					}
				}
			}

			if (!is_opened) {
				for (let i = 0; i < models.length; i++) {

					let k = models[i].name

					this.three.scene.remove(this.three.scene.getObjectByName(k));

					this.three[k] = this.getMesh(k, {
						width: Number(width + 18),
						height: Number(height + 18),
					},)

					this.three[k].scale.set(0.10, 0.10, 0.10)


					this.three[k].position.z = models[i].z

					if (k === 'frame_inside') {
						this.three.inside_group.add(this.three[k])
						this.three[k].position.x = -0.15
					} else if (k === 'frame_inside_front') {
						this.three.inside_group.add(this.three[k])
						this.three[k].position.x = -0.15
					} else if (k === 'frame_outside_colored') this.three.outside_group.add(this.three[k])
					else if (k === 'frame_inside_colored') this.three.outside_group.add(this.three[k])
					else if (k === 'glass') this.three.outside_group.add(this.three[k])
				}

			}

			if (this.changeHeight >= range.min && this.changeHeight <= range.max) {
				let k = this.changeHeight / -30
				this.three.handle_model.scale.set(0.013, 0.013, 0.013)

				for (let model of models) {
					this.three[model.name].position.y = k
				}
				for (let model of this.grids_array) {
					this.three[model.name].position.y = k
				}
			} else if (this.changeHeight < range.min) {
				let k = this.changeHeight / -24

				this.three.handle_model.scale.set(0.013, 0.013, 0.013)

				for (let model of models) {
					this.three[model.name].position.y = k
				}
				for (let model of this.grids_array) {
					this.three[model.name].position.y = k
				}
			}

			this.three.pivot_global.add(this.three.inside_group);
			this.three.scene.add(this.three.pivot_global)

			for (let item of this.three.inside_group.children) {
				if (item.name.includes('grid_horizontal_inside') || item.name.includes('grid_horizontal_outside') || item.name.includes('grid_vertical_inside') || item.name.includes('grid_vertical_outside')) {
					item.position.x = -this.changeWidth / 33 * 2
				}
			}
			_this.handleSide()
		},

		handleSide: function () {
			let model = this.three.handle_model
			model.position.x = -10000

			model.position.x = 0.2
			model.position.y = 0
			if (this.changeHeight <= 50) {
				model.position.y = 0.2
			}

			this.three.outside_group.position.x = -this.changeWidth / 33
			this.three.inside_group.position.x = this.changeWidth / 33

			this.three.i_g = new THREE.Group()
			this.three.i_g.add(this.three.handle_model)
			this.three.i_g.position.x = -this.changeWidth / 33 * 2 - 0.15

			this.three.inside_group.add(this.three.i_g)
			this.three.inside_group.add(this.three.glass)

			this.three.frame_inside.position.x = -this.changeWidth / 33 * 2 - 0.15
			this.three.frame_inside_front.position.x = -this.changeWidth / 33 * 2 - 0.15
			this.three.glass.position.x = -this.changeWidth / 33 * 2 - 0.15

			for (let item of this.three.inside_group.children) {
				if (item.name.includes('grid_horizontal_inside') || item.name.includes('grid_horizontal_outside') || item.name.includes('grid_vertical_inside') || item.name.includes('grid_vertical_outside')) {
					item.position.x = -this.changeWidth / 33 * 2
				}
			}
			model.position.x = -10000
		},

		addToCart() {
			let url = this.isAdmin ? '/admin/cart' : '/cart'
			let data = this.getWindowFixedValues()
			data.type = 'window fixed'

			axios.post(url, data).then((response) => {
                console.log(response);
				if (!this.isAdmin) {
                    console.log( process.env.MIX_FRONT_APP_URL + "/cart/")
					document.cookie = "page=" + process.env.MIX_FRONT_APP_URL + "/vindu-fast/"
					window.top.location.href = process.env.MIX_FRONT_APP_URL + "/cart/"
				} else {
					window.location.href = "/admin/cart";
				}
			})
		}
	},
}
</script>
